import React from 'react'

import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { ScreenProps } from '../types'
import { Button } from 'components/core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
import { BackIconButton } from '../../core/buttons/BackIconButton'
import { appConfig } from '../../../appConfig'

export type FormValues = { [key: string]: string }
export type ToggleInputsFormValidationSchema = any | (() => any)

export interface ToggleInputsFormProps {
  body: React.ReactNode
  initialValues?: FormValues
  validationSchema?: ToggleInputsFormValidationSchema
}

export interface ToggleInputsFormScreenProps extends ScreenProps {
  headerBar: React.ReactNode
  sectionTitle?: React.ReactNode
  screenTitle: React.ReactNode
  description?: React.ReactNode
  icon?: React.ReactNode
  buttonText?: React.ReactNode
  form: ToggleInputsFormProps
}

export const ToggleInputsFormScreen: React.FC<ToggleInputsFormScreenProps> = ({
  headerBar,
  sectionTitle,
  screenTitle,
  description,
  icon,
  buttonText,
  form,

  onNextClicked,
  onBackClicked,
}) => {
  const { body, initialValues = {}, validationSchema } = form

  return (
    <>
      <div className="bg-background">
        <LogoHeader />
        {headerBar}
        <main className="flex flex-row flex-1 w-full justify-center">
          <div
            className="flex flex-col max-w-info-width-mobile sm:max-w-info-width-desktop justify-between mt-10 sm:mt-20 sm:justify-start pb-10"
            style={{ boxSizing: 'initial' }}
          >
            <div className="flex mb-4 sm:mb-6 items-center justify-between">
              {onBackClicked ? (
                <div className="mr-2 pt-1">
                  <BackIconButton onClick={onBackClicked} />
                </div>
              ) : (
                <div style={{ width: '20px' }}></div>
              )}
              <p className="text-base-primary light">{sectionTitle}</p>
              <div style={{ width: '20px' }}></div>
            </div>
            <h3 className="text-center mt-4">{screenTitle}</h3>
            {description && <p className="light text-center mt-6">{description}</p>}
            {icon && (
              <img src={`${appConfig.publicUrl}/img/icons/${icon}.svg`} alt="Scroll down" className="h-8 mt-8" />
            )}
          </div>
        </main>
      </div>
      <div className="bg-white">
        <main className="flex flex-row flex-1 w-full justify-center">
          <div>
            {form && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  onNextClicked(values)
                  setSubmitting(false)
                }}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form className="mt-8 w-form-width-mobile sm:w-form-width-desktop">
                    {body}
                    <div className="mt-20 mb-12 text-center">
                      <Button type="submit" disabled={!(isValid && dirty) || isSubmitting} altbackground="background">
                        {buttonText || <FormattedMessage id="next" />}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </main>
      </div>
    </>
  )
}
