import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Quizes } from 'redux/quiz/types'

export interface Navigator {
  previous: () => void
  next: (currentQuiz?: Quizes) => void
  currentPage: string
}

export const useNavigator = (paths: string[], pathPrefix: string, quiz: Quizes): Navigator => {
  const history = useHistory()
  const location = useLocation()

  const currentPage = useMemo(() => location.pathname, [location])
  const currentPageIndex = useMemo(() => paths.findIndex((v) => v === location.pathname), [currentPage])

  const previous = useCallback(() => {
    let previousPagePath: string = paths[currentPageIndex - 1]

    if (currentPage === `${pathPrefix}cant-remember`) {
      previousPagePath = `${pathPrefix}start-date`
    }

    if (currentPage === `${pathPrefix}transit-time`) {
      previousPagePath = `${pathPrefix}start-date`
    }
    history.push(previousPagePath)
  }, [history, paths, currentPage])

  const next = (currentQuiz?: Quizes) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const quizToCheck: Quizes = currentQuiz === undefined ? quiz : currentQuiz
    let nextPagePath: string = paths[currentPageIndex + 1]

    if (currentPage === `${pathPrefix}cant-remember`) {
      nextPagePath = `${pathPrefix}study-1`
    }

    if (currentPage === `${pathPrefix}end-date`) {
      nextPagePath = `${pathPrefix}study-1`
    }

    history.push(nextPagePath)
  }

  return { previous, next, currentPage }
}
