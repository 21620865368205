import React, { useEffect } from 'react'

export interface ExitScreenProps {
  to: string
}

export const ExitScreen: React.FC<ExitScreenProps> = ({ to }) => {
  useEffect(() => window.location.assign(to), [])
  return null
}
