import { appConfig } from '../../../../appConfig'
import React from 'react'
import { GoodMicrobesNickname } from '../../../../redux/quiz/types'

interface GutMicrobeIconProps {
  number: number
  nickname: GoodMicrobesNickname
  present: boolean
  showNickname?: boolean
  eyes?: boolean
}

export const GutMicrobeIcon: React.FC<GutMicrobeIconProps> = ({
  number,
  nickname,
  present,
  showNickname = true,
  eyes = false,
}) => {
  let iconName = nickname.toLowerCase()
  iconName = present ? iconName : iconName + '-blank'
  return (
    <div className="flex flex-col mt-2 mb-0 px-1 items-center">
      <div className="w-microbe-width-mobile h-microbe-height-mobile sm:w-microbe-width-desktop sm:h-microbe-height-desktop">
        {eyes && !present ? null : (
          <img
            src={`${appConfig.publicUrl}/img/microbes/${eyes ? 'eyes/' : ''}${iconName}.svg`}
            alt="Microbe icon"
            className="mx-auto h-full w-full"
          />
        )}
      </div>
      {showNickname ? (
        <p className={`text-footnote sm:text-base sm:mt-2 text-base-primary ${present ? '' : ' text-opacity-50'}`}>
          {nickname}
          <span style={{ fontSize: '8px', lineHeight: '16px', verticalAlign: 'text-top', display: 'inline-block' }}>
            {number}
          </span>
        </p>
      ) : null}
    </div>
  )
}
