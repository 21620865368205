import React from 'react'
import { FormattedMessage } from 'react-intl'
import { appConfig } from '../../../../appConfig'
import { BluePoopChallengeHashtag } from './BluePoopChallengeHashtag'
import { GutTwinBioStats } from './GutTwinBioStats'
import { Polaroid } from './Polaroid'

export interface GutTwinResultProps {
  name: string
  age: number
  twinName: string
  twinGender: string
  twinHeightFt: number
  twinHeightIn: number
  twinAge: number
  twinWeightLbs: number
}

export const GutTwinResult: React.FC<GutTwinResultProps> = ({
  name,
  age,
  twinName,
  twinGender,
  twinHeightFt,
  twinHeightIn,
  twinAge,
  twinWeightLbs,
}) => {
  return (
    <div className="w-full">
      <div className="bg-results-background-teal mx-auto pt-6 pb-16 sm:pb-20 max-w-info-width-mobile sm:max-w-results-width-desktop text-center">
        <h3 className="light">
          <FormattedMessage id="quiz.gut_twin.title" />
        </h3>
        <div className="my-8">
          {/* Note: twinAge is hardcoded to 20 for v1 as we have just 2 images  */}
          <div className="relative mt-2" style={{ margin: 'auto', height: '250px', width: '230px' }}>
            <div className="absolute" style={{ top: '70px', left: '55px' }}>
              <Polaroid twinGender={twinGender} twinName={name} twinAge={age} rotation={'rotate-6'} image={'bg.png'} />
            </div>
            <div className="absolute" style={{ left: '0' }}>
              <Polaroid twinGender={twinGender} twinName={twinName} twinAge={twinAge} rotation={'-rotate-6'} />
            </div>
          </div>
          <div className="mt-10 mb-8">
            <GutTwinBioStats
              gender={twinGender}
              heightFt={twinHeightFt}
              heightIn={twinHeightIn}
              age={twinAge}
              weightLbs={twinWeightLbs}
            />
          </div>
        </div>
        <BluePoopChallengeHashtag />
        <p className="text-fineprint light mt-4 opacity-60 sm:text-center sm:mt-10">
          <FormattedMessage id="quiz.gut_twin.name.disclaimer" values={{ twinName }} />
        </p>
      </div>

      <div className="bg-results-background-dark-green relative pt-8 sm:pt-24">
        <div
          className={`w-0 h-0 border-b-14 border-results-background-dark-green right-section-arrow absolute`}
          style={{
            borderLeft: '14px solid transparent',
            borderRight: '14px solid transparent',
            top: -14,
          }}
        ></div>
        <div className="max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto px-2 py-8 sm:bg-results-background-teal sm:rounded-2xl sm:py-12 relative">
          <div
            className={`hidden sm:inline-block w-0 h-0 border-t-14 border-results-background-teal left-large-speech-arrow absolute`}
            style={{
              borderRight: '14px solid transparent',
              bottom: -14,
            }}
          ></div>
          <img
            src={`${appConfig.publicUrl}/img/poop/partypooper.png`}
            alt="Partypooper image"
            className="w-poop-personality-character-front absolute hidden sm:block"
            style={{
              bottom: -60,
              left: 45,
            }}
          />
          <div className="text-center sm:max-w-transit-graph-card mx-auto">
            <h2 className="uppercase tracking-wider mb-3">
              <FormattedMessage id="quiz.gut_twin.whats_gut_twin" />
            </h2>
            <p className="light mx-auto">
              <FormattedMessage id="quiz.gut_twin.whats_gut_twin.description" />
            </p>
          </div>
        </div>

        <div className="mb-20 mt-6 sm:mt-32 sm:mb-40">
          <div
            className={`bg-results-background-teal rounded-2xl pt-3 pb-4 px-8 w-small-speech-bubble relative mx-auto`}
          >
            <p className="light text-center">
              <FormattedMessage id="quiz.gut_twin.two_peas" />
            </p>
            <div
              className={`w-0 h-0 border-t-14 border-results-background-teal right-speech-arrow-left absolute`}
              style={{
                borderLeft: '14px solid transparent',
                bottom: -14,
              }}
            ></div>
            <img
              src={`${appConfig.publicUrl}/img/poop/pooptastic.png`}
              alt="Pooptastic says like two peas in a pod"
              className="w-poop-personality-character-side transform -rotate-12 -mr-12 absolute"
              style={{ top: -20, right: -70 }}
            />
          </div>
        </div>
        <div className="max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto px-2 pt-8 sm:bg-results-background-teal sm:rounded-2xl sm:mt-8 sm:py-12">
          <div className="text-center sm:max-w-transit-graph-card mx-auto">
            <h2 className="uppercase tracking-wider mb-3">
              <FormattedMessage id="quiz.gut_twin.why_care" />
            </h2>
            <p className="light mx-auto">
              <FormattedMessage id="quiz.gut_twin.why_care.description" />
            </p>
          </div>
        </div>
        <hr className="mt-12 w-info-width-mobile mx-auto border-divider-gut-twin sm:hidden" />
      </div>
    </div>
  )
}
