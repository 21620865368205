import React from 'react'
import { RadioButtonSharp } from './RadioButtonSharp'
import { RadioButtonGroup } from './RadioButtonGroup'
import { useIntl } from 'react-intl'
import { appConfig } from '../../../appConfig'

export interface FFQuestionProps {
  label: string
  subLabel?: string
  foodGroup: string
  icon: string
  status: string
  updateStatus: (arg1: string) => void
}

export const FFQuestion: React.FC<FFQuestionProps> = ({ label, subLabel, foodGroup, icon, status, updateStatus }) => {
  const intl = useIntl()

  const DEFAULT_CHOICES = (
    <RadioButtonGroup name={`ffq_${foodGroup}`} className="mb-8" errorClassName="hidden">
      <div className="grid gap-2 grid-cols-2">
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="rarely_or_never"
          value="rarely_or_never"
          label={intl.formatMessage({ id: 'rarely_or_never' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="less_than_once_a_week"
          value="less_than_once_a_week"
          label={intl.formatMessage({ id: 'less_than_once_a_week' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="once_a_week"
          value="once_a_week"
          label={intl.formatMessage({ id: 'once_a_week' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="2_3_times_a_week"
          value="2_3_times_a_week"
          label={intl.formatMessage({ id: '2_3_times_a_week' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="4_6_times_a_week"
          value="4_6_times_a_week"
          label={intl.formatMessage({ id: '4_6_times_a_week' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="1_2_times_a_day"
          value="1_2_times_a_day"
          label={intl.formatMessage({ id: '1_2_times_a_day' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="3_4_times_a_day"
          value="3_4_times_a_day"
          label={intl.formatMessage({ id: '3_4_times_a_day' })}
          clicked={updateStatus}
        />
        <RadioButtonSharp
          name={`ffq_${foodGroup}`}
          id="5+_times_a_day"
          value="5+_times_a_day"
          label={intl.formatMessage({ id: '5+_times_a_day' })}
          clicked={updateStatus}
        />
      </div>
    </RadioButtonGroup>
  )

  const displayChoices = (status: string) => {
    if (status === 'incomplete') {
      return DEFAULT_CHOICES
    } else if (status === '') {
      return null
    } else {
      return (
        <div
          onClick={() => {
            updateStatus('incomplete')
          }}
          className="grid gap-2 grid-cols-2 mb-8"
        >
          <RadioButtonSharp name={`ffq_${foodGroup}`} label={intl.formatMessage({ id: status })} />
        </div>
      )
    }
  }
  return (
    <div className="mb-8">
      <div className="mb-4 flex align-middle">
        <img src={`${appConfig.publicUrl}/img/food-groups/${icon}.png`} alt={label} className="h-6 mr-2" />
        <p className="text-base-secondary light">
          {label}
          <span className="text-base-tertiary light"> {subLabel}</span>
        </p>
      </div>
      {displayChoices(status)}
    </div>
  )
}
