import React, { useCallback } from 'react'
import { Button } from '../../../core/buttons/Button'
import { onNextClickedType } from '../../types'
import { appConfig } from '../../../../appConfig'
import { TextLinkButton } from '../../../core/buttons/TextLinkButton'
import { useHistory } from 'react-router-dom'

export interface ResultsFooterProps {
  backgroundColour: string
  textAboveButton?: React.ReactNode
  buttonText: React.ReactNode
  showHomepageLink?: boolean
  onNextClicked: onNextClickedType
}

export const ResultsFooter: React.FC<ResultsFooterProps> = ({
  backgroundColour,
  textAboveButton,
  buttonText,
  showHomepageLink,
  onNextClicked,
}) => {
  const nextPageHandler = useCallback(() => {
    onNextClicked()
  }, [onNextClicked])

  const history = useHistory()

  return (
    <div className={`w-full pt-12 sm:pt-16 pb-16 sm:pb-20 bg-${backgroundColour}`}>
      <div className="mx-auto max-w-results-width-mobile sm:max-w-info-width-desktop text-center">
        {textAboveButton && (
          <>
            <h4 className="mb-4 light">{textAboveButton}</h4>
            <img
              src={`${appConfig.publicUrl}/img/icons/down-arrow.svg`}
              alt="Down arrow icon"
              className="mx-auto mb-6"
            />
          </>
        )}

        {showHomepageLink ? (
          <>
            <Button onClick={nextPageHandler} altbackground="zoe-active">
              {buttonText}
            </Button>
            <TextLinkButton onClick={() => history.push('poop-personality')} className="mb-8 mx-auto block">
              <p className="mt-5 block light text-zoe-active underline">Back to my report</p>
            </TextLinkButton>
          </>
        ) : (
          <Button onClick={nextPageHandler}>{buttonText}</Button>
        )}
      </div>
    </div>
  )
}
