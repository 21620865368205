import React from 'react'

import { useField, useFormikContext } from 'formik'
import { FieldError } from './FieldError'
import { FieldLabel } from './FieldLabel'

interface SelectFieldOption {
  label: string
  value: string
}

export interface SelectFieldProps extends React.ComponentProps<'select'> {
  label?: React.ReactNode
  name: string
  options: SelectFieldOption[]
  selected?: (arg0: string) => void
}

export const SelectField: React.FC<SelectFieldProps> = ({ label, selected, ...props }) => {
  const [field, meta] = useField(props)
  const context = useFormikContext()

  const onChangeValidation = async (e: React.ChangeEvent<{ value: unknown }>) => {
    field.onChange(e) // manually tell Formik to update the field value, then check overall Form validation
    await context.validateForm({
      ...(context.values as any),
      [props.name]: e.target.value, // ensures validation with the new value
    })

    selected ? selected(e.target.value as string) : null
  }
  return (
    <div className="flex flex-col">
      {label && (
        <FieldLabel htmlFor={props.id || props.name} className="light mb-2">
          {label}
        </FieldLabel>
      )}
      <select
        className="appearance-none bg-background-secondary bg-dropdown-arrow bg-no-repeat bg-right-16 h-input-field px-6 border border-transparent focus:outline-none focus:border-divider-secondary placeholder-white"
        {...field}
        {...props}
        onChange={onChangeValidation}
      >
        {props.options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
