import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { useQuizService } from 'contexts/QuizServiceContext'
import { selectQuiz, updateQuiz } from 'redux/quiz/slice'
import { QuizVersion } from 'redux/quiz/types'

export interface EntryScreenProps extends RouteComponentProps {
  to: string
  quizVersion: QuizVersion
}

export const EntryScreen: React.FC<EntryScreenProps> = ({ to, location, quizVersion }) => {
  const quiz = useSelector(selectQuiz)
  const dispatch = useDispatch()
  const { quizApi } = useQuizService()

  // @ts-ignore
  useEffect(() => {
    if (!quiz[quizVersion]?.poop_personality) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_id_defined',
          user_id: quiz.id,
        },
      })

      const answers = [{ question_key: 'quiz_version', answer: quizVersion }]

      if (location.search) {
        // @ts-ignore
        answers.push({ question_key: 'query_params', answer: location.search })
      }

      try {
        quizApi.answers({
          user_id: quiz.id,
          quiz_version: quizVersion,
          answers,
        })

        dispatch(
          updateQuiz({
            quiz: {},
            version: quizVersion,
          }),
        )
      } catch (error) {
        console.error(error)
      }
    }
  }, [quizApi])

  if (quiz[quizVersion]?.poop_personality) {
    return <Redirect to="/poop-personality" />
  } else {
    return <Redirect to={{ pathname: to, search: location?.search }} />
  }
}
