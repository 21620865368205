import React from 'react'
import { FormattedMessage } from 'react-intl'
import { appConfig } from '../../../../appConfig'

export interface GutMicrobesResultCardProps {
  twinGoodBugsNumber: number
}

export const GutMicrobesResultCard: React.FC<GutMicrobesResultCardProps> = ({ twinGoodBugsNumber }) => {
  return (
    <div className={`w-full h-full bg-results-background-deep-blue flex justify-center items-center`}>
      <div>
        <img
          src={`${appConfig.publicUrl}/img/microbes/stack.png`}
          alt="Microbes"
          className="mx-auto w-gut-microbes-stack-mobile sm:w-gut-microbes-stack-desktop"
        />
        <p className="uppercase text-fineprint-spread sm:text-footnote-bold tracking-wider mt-3 sm:mt-5 px-4">
          <FormattedMessage
            id="quiz.summary.gut_microbes.statement"
            values={{ twinGoodBugsNumber: twinGoodBugsNumber }}
          />
        </p>
      </div>
    </div>
  )
}
