import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'components/core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
export interface ErrorScreenProps {
  headline: React.ReactNode
  description: React.ReactNode
  redirectTo: string
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ headline, description, redirectTo }) => {
  return (
    <div className="h-full bg-background">
      <LogoHeader />
      <main className="flex flex-row flex-1 w-full justify-center bg-background pb-12">
        <div className="flex flex-col max-w-info-width-mobile sm:max-w-results-width-desktop px-2 mt-20 sm:mt-40">
          <div className="flex flex-col items-center">
            <h1 className="text-center light">{headline}</h1>
            <div className="mt-8">{description}</div>
          </div>

          <div className="mt-20 mb-16 text-center">
            <Button onClick={() => window.location.assign(redirectTo)}>
              <FormattedMessage id="start_again" />
            </Button>
          </div>

          <div className="max-w-form-width-mobile sm:max-w-form-width-desktop bg-background-secondary text-center p-6 mx-auto rounded-lg">
            <p className="light mx-auto">
              <FormattedMessage id="quiz.results_error.fun_fact" />
            </p>
            <h4 className="light mx-auto mt-4">
              <FormattedMessage id="quiz.results_error.fun_fact.description" />
            </h4>
          </div>
        </div>
      </main>
    </div>
  )
}
