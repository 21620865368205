import { Answer } from '../redux/quiz/slice'

export const transitTime = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  ttDaysInput: number | string,
  ttHoursInput: number | string,
): Answer[] | undefined => {
  if ((startDate === '' || endDate === '') && ttDaysInput === '') {
    return undefined
  } else if (startDate !== '' && endDate !== '') {
    const startDateTime = new Date(startDate + ' ' + startTime + ':00')
    const endDateTime = new Date(endDate + ' ' + endTime + ':00')
    const transitTimeHours = ((endDateTime.valueOf() - startDateTime.valueOf()) / (1000 * 60 * 60)).toFixed(2)
    return [{ question_key: 'transit_time_hrs', answer: transitTimeHours }]
  } else {
    const transitTimeDaysParsed = typeof ttDaysInput === 'string' ? parseFloat(ttDaysInput) : ttDaysInput
    const transitTimeHoursParsed = typeof ttHoursInput === 'string' ? parseFloat(ttHoursInput) : ttHoursInput
    const transitTimeHours = transitTimeDaysParsed * 24 + transitTimeHoursParsed
    return [{ question_key: 'transit_time_hrs', answer: transitTimeHours }]
  }
}
