import React from 'react'
import { PoopPersonalityName } from '../../../../redux/quiz/types'
import { Button } from '../../../core/buttons/Button'
import TagManager from 'react-gtm-module'
import { instagramDeepLinkLayer } from '../../gtm'

export interface CopyForInstagramButtonProps {
  poopPersonalityName: PoopPersonalityName
  buttonText?: React.ReactNode
}

export const CopyForInstagramButton: React.FC<CopyForInstagramButtonProps> = ({ poopPersonalityName, buttonText }) => {
  function copyText() {
    const tempInput = document.createElement('input')
    tempInput.value =
      'Turns out I’ve got a ' +
      poopPersonalityName +
      ". What's your poop personality? Take the #bluepoopchallenge" +
      ' to find out.'
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
  }

  const sendToGTM = () => {
    TagManager.dataLayer({
      dataLayer: instagramDeepLinkLayer(),
    })
  }

  // TODO: Check on native devices
  function copyAndOpenInstagram() {
    sendToGTM()
    copyText()
    window.location.assign('https://instagram.com/zoe')
  }

  return (
    <>
      {document.queryCommandSupported('copy') && (
        <Button id="copy-icon" onClick={() => copyAndOpenInstagram()} altbackground="background">
          {buttonText}
        </Button>
      )}
    </>
  )
}
