import React, { useEffect } from 'react'
import { PoopPersonalityName } from '../../../../redux/quiz/types'
import { FormattedMessage } from 'react-intl'
import { POOP_PERSONALITIES } from '../constants'
import { GutHealthTip } from './GutHealthTip'
import { ResultCard } from './ResultCard'
import { BluePoopChallengeHashtag } from './BluePoopChallengeHashtag'
import { useHistory } from 'react-router-dom'
import { Button } from '../../../core/buttons/Button'
import { TextLinkButton } from '../../../core/buttons/TextLinkButton'
import TagManager from 'react-gtm-module'
import { summaryCtaDataLayer } from '../../gtm'
import { SharingLinks } from './SharingLinks'

export interface SummaryResultProps {
  poopPersonalityName: PoopPersonalityName
  transitTimeHrs: number
  name: string
  age: number
  twinName: string
  twinGender: 'male' | 'female'
  twinAge: number
  twinGoodBugsNumber: number
}

export const SummaryResult: React.FC<SummaryResultProps> = ({
  poopPersonalityName,
  transitTimeHrs,
  name,
  age,
  twinName,
  twinGender,
  twinAge,
  twinGoodBugsNumber,
}) => {
  const history = useHistory()
  const sendToGTM = () => {
    TagManager.dataLayer({
      dataLayer: summaryCtaDataLayer(),
    })
  }
  const ctaButtonHandler = () => {
    sendToGTM()
    history.push('done')
  }

  useEffect(() => {
    if (POOP_PERSONALITIES[poopPersonalityName] === undefined) {
      history.push('results-error')
    }
  }, [])
  const poopPersonalityType = POOP_PERSONALITIES[poopPersonalityName]?.poopPersonalityType
  return (
    <div className="w-full">
      <div className="text-base-secondary pt-6 pb-16 sm:pb-20 text-center mx-auto">
        <h3 className="light">
          <FormattedMessage id="quiz.summary.title" />
        </h3>
        <div className="my-12 sm:my-20 mx-auto max-w-sharing-asset text-base-secondary grid grid-cols-2 sm:grid-cols-4 sm:gap-x-52 sm:justify-end">
          <ResultCard
            metric="poopPersonality"
            poopPersonalityName={poopPersonalityName}
            className="transform rotate-1 relative left-10px"
          />
          <ResultCard
            metric="transitTime"
            transitTimeHrs={transitTimeHrs}
            className="transform -rotate-2 relative right-10px top-15px z-10"
          />
          <ResultCard
            metric="gutTwin"
            name={name}
            age={age}
            twinName={twinName}
            twinGender={twinGender}
            twinAge={twinAge}
            className="transform -rotate-3 x z-20 sm:rotate-3 sm:relative sm:right-15px"
          />
          <ResultCard
            metric="gutMicrobes"
            twinGoodBugsNumber={twinGoodBugsNumber}
            className="transform rotate-2 relative top-15px right-15px z-30 sm:-rotate-2"
          />
        </div>
        <BluePoopChallengeHashtag darkText={true} />
        <div className="mt-12">
          <p className="uppercase text-footnote text-base-secondary tracking-widest pb-6">
            <FormattedMessage id="quiz.summary.social" />
          </p>
          <SharingLinks poopPersonalityType={poopPersonalityType} poopPersonalityName={poopPersonalityName} />
        </div>
      </div>

      <div className="h-gut-tip-mobile sm:h-gut-tip-desktop">
        <GutHealthTip poopPersonalityType={poopPersonalityType} />
      </div>

      <div className="bg-no-repeat bg-center bg-cover bg-summary-final-module-mobile sm:bg-summary-final-module-desktop">
        <div className="text-base-secondary max-w-info-width-mobile mx-auto text-center sm:max-w-info-width-desktop pt-16 px-2">
          <h2 className="text-strong-h2 light mb-6 text-base-secondary">
            <FormattedMessage id="quiz.summary.conclusion.title" />
          </h2>
          <p className="light text-base-secondary">
            <FormattedMessage id="quiz.summary.conclusion.statement" values={{ br: <br /> }} />
          </p>
        </div>
        <div className="text-center mt-12 pb-48">
          <Button onClick={ctaButtonHandler} altbackground="zoe-active">
            <FormattedMessage id="learn_more" />
          </Button>
          <TextLinkButton onClick={() => history.push('poop-personality')} className="mb-8 mx-auto block">
            <p className="mt-5 block light text-zoe-active underline">Back to my report</p>
          </TextLinkButton>
        </div>
      </div>
    </div>
  )
}
