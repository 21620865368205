/* tslint:disable */
/* eslint-disable */
/**
 * quiz-service
 * Stores joinzoe.com quiz answers and syncs to Mailchimp
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'
import { PoopPersonalityName } from '../../redux/quiz/types'

/**
 *
 * @export
 * @interface Answer
 */
export interface Answer {
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  question_key: string
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  answer: string
}
/**
 *
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
  /**
   *
   * @type {string}
   * @memberof AnswerRequest
   */
  question_key: string
  /**
   *
   * @type {string}
   * @memberof AnswerRequest
   */
  answer: string
  /**
   *
   * @type {string}
   * @memberof AnswerRequest
   */
  user_id: string
  /**
   *
   * @type {string}
   * @memberof AnswerRequest
   */
  quiz_version: string
}
/**
 *
 * @export
 * @interface AnswersRequest
 */
export interface AnswersRequest {
  /**
   *
   * @type {string}
   * @memberof AnswersRequest
   */
  user_id: string
  /**
   *
   * @type {string}
   * @memberof AnswersRequest
   */
  quiz_version: string
  /**
   *
   * @type {Array<Answer>}
   * @memberof AnswersRequest
   */
  answers: Array<Answer>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum EmailType {
  Html = 'html',
  Text = 'text',
}

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MailchimpWebhookType {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  Cleaned = 'cleaned',
  Profile = 'profile',
  Upemail = 'upemail',
  Campaign = 'campaign',
}

/**
 *
 * @export
 * @interface MailchimpWebookData
 */
export interface MailchimpWebookData {
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  action?: string
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  reason?: string
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  list_id: string
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  email: string
  /**
   *
   * @type {EmailType}
   * @memberof MailchimpWebookData
   */
  email_type: EmailType
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  ip_opt: string
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  web_id?: string
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  ip_signup?: string
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof MailchimpWebookData
   */
  merges: { [key: string]: string }
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookData
   */
  campaign_id?: string
}
/**
 *
 * @export
 * @interface MailchimpWebookRequest
 */
export interface MailchimpWebookRequest {
  /**
   *
   * @type {MailchimpWebhookType}
   * @memberof MailchimpWebookRequest
   */
  type: MailchimpWebhookType
  /**
   *
   * @type {string}
   * @memberof MailchimpWebookRequest
   */
  fired_at: string
  /**
   *
   * @type {MailchimpWebookData}
   * @memberof MailchimpWebookRequest
   */
  data: MailchimpWebookData
}
/**
 *
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  quiz_version: string
}
/**
 *
 * @export
 * @interface RegistrationResponse
 */
export interface RegistrationResponse {
  /**
   *
   * @type {string}
   * @memberof RegistrationResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RegistrationResponse
   */
  email: string
}
/**
 *
 * @export
 * @interface ResultsResponse
 */
export interface ResultsResponse {
  /**
   *
   * @type {string}
   * @memberof ResultsResponse
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  transit_time_hrs: number
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  transit_time_position: number
  /**
   *
   * @type {string}
   * @memberof ResultsResponse
   */
  poop_personality: PoopPersonalityName
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  twin_age: number
  /**
   *
   * @type {string}
   * @memberof ResultsResponse
   */
  twin_name: string
  /**
   *
   * @type {string}
   * @memberof ResultsResponse
   */
  twin_sex: string
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  twin_height_ft: number
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  twin_height_in: number
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  twin_weight: number
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  twin_good_bugs_number: number
  /**
   *
   * @type {Array<string>}
   * @memberof ResultsResponse
   */
  twin_good_bugs_names: Array<string>
  /**
   *
   * @type {string}
   * @memberof ResultsResponse
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof ResultsResponse
   */
  age: number
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationError
   */
  loc: Array<string>
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string
}

/**
 * BluepoopApi - axios parameter creator
 * @export
 */
export const BluepoopApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get transit time and gut twin results
     * @summary Results
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    results: async (userId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('results', 'userId', userId)
      const localVarPath = `/bluepoop/results/{user_id}`.replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BluepoopApi - functional programming interface
 * @export
 */
export const BluepoopApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BluepoopApiAxiosParamCreator(configuration)
  return {
    /**
     * Get transit time and gut twin results
     * @summary Results
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async results(
      userId: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.results(userId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * BluepoopApi - factory interface
 * @export
 */
export const BluepoopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BluepoopApiFp(configuration)
  return {
    /**
     * Get transit time and gut twin results
     * @summary Results
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    results(userId: string, options?: any): AxiosPromise<ResultsResponse> {
      return localVarFp.results(userId, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * BluepoopApi - object-oriented interface
 * @export
 * @class BluepoopApi
 * @extends {BaseAPI}
 */
export class BluepoopApi extends BaseAPI {
  /**
   * Get transit time and gut twin results
   * @summary Results
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BluepoopApi
   */
  public results(userId: string, options?: any) {
    return BluepoopApiFp(this.configuration)
      .results(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * QuizApi - axios parameter creator
 * @export
 */
export const QuizApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add or update an answer to a question
     * @summary Answer
     * @param {AnswerRequest} answerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answer: async (answerRequest: AnswerRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'answerRequest' is not null or undefined
      assertParamExists('answer', 'answerRequest', answerRequest)
      const localVarPath = `/quiz/answer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(answerRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Add or update a list of answers
     * @summary Answers
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answers: async (answersRequest: AnswersRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'answersRequest' is not null or undefined
      assertParamExists('answers', 'answersRequest', answersRequest)
      const localVarPath = `/quiz/answers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(answersRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Add or update a list of answers from blue poop quiz. This specifically kicks off a background task to calculate diet score and gut twin. Mailchimp is not updated through this endpoint.
     * @summary Blue Poop Calc Results
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bluePoopCalcResults: async (answersRequest: AnswersRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'answersRequest' is not null or undefined
      assertParamExists('bluePoopCalcResults', 'answersRequest', answersRequest)
      const localVarPath = `/quiz/blue-poop-calc-results`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(answersRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Add or update a list of answers without re-running diet score or gut twin calcs. Mailchimp will be updated with new answers and results. The background task does a check to see if all required Mailchimp fields are present - if not, it waits for 5 seconds, then sends whatever is available at that time.
     * @summary Blue Poop Follow Up Answers
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bluePoopFollowUpAnswers: async (answersRequest: AnswersRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'answersRequest' is not null or undefined
      assertParamExists('bluePoopFollowUpAnswers', 'answersRequest', answersRequest)
      const localVarPath = `/quiz/blue-poop-follow-up-answers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(answersRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * QuizApi - functional programming interface
 * @export
 */
export const QuizApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QuizApiAxiosParamCreator(configuration)
  return {
    /**
     * Add or update an answer to a question
     * @summary Answer
     * @param {AnswerRequest} answerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async answer(
      answerRequest: AnswerRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.answer(answerRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Add or update a list of answers
     * @summary Answers
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async answers(
      answersRequest: AnswersRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.answers(answersRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Add or update a list of answers from blue poop quiz. This specifically kicks off a background task to calculate diet score and gut twin. Mailchimp is not updated through this endpoint.
     * @summary Blue Poop Calc Results
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bluePoopCalcResults(
      answersRequest: AnswersRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bluePoopCalcResults(answersRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Add or update a list of answers without re-running diet score or gut twin calcs. Mailchimp will be updated with new answers and results. The background task does a check to see if all required Mailchimp fields are present - if not, it waits for 5 seconds, then sends whatever is available at that time.
     * @summary Blue Poop Follow Up Answers
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bluePoopFollowUpAnswers(
      answersRequest: AnswersRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bluePoopFollowUpAnswers(answersRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * QuizApi - factory interface
 * @export
 */
export const QuizApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = QuizApiFp(configuration)
  return {
    /**
     * Add or update an answer to a question
     * @summary Answer
     * @param {AnswerRequest} answerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answer(answerRequest: AnswerRequest, options?: any): AxiosPromise<any> {
      return localVarFp.answer(answerRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Add or update a list of answers
     * @summary Answers
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answers(answersRequest: AnswersRequest, options?: any): AxiosPromise<any> {
      return localVarFp.answers(answersRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Add or update a list of answers from blue poop quiz. This specifically kicks off a background task to calculate diet score and gut twin. Mailchimp is not updated through this endpoint.
     * @summary Blue Poop Calc Results
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bluePoopCalcResults(answersRequest: AnswersRequest, options?: any): AxiosPromise<any> {
      return localVarFp.bluePoopCalcResults(answersRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Add or update a list of answers without re-running diet score or gut twin calcs. Mailchimp will be updated with new answers and results. The background task does a check to see if all required Mailchimp fields are present - if not, it waits for 5 seconds, then sends whatever is available at that time.
     * @summary Blue Poop Follow Up Answers
     * @param {AnswersRequest} answersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bluePoopFollowUpAnswers(answersRequest: AnswersRequest, options?: any): AxiosPromise<any> {
      return localVarFp.bluePoopFollowUpAnswers(answersRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * QuizApi - object-oriented interface
 * @export
 * @class QuizApi
 * @extends {BaseAPI}
 */
export class QuizApi extends BaseAPI {
  /**
   * Add or update an answer to a question
   * @summary Answer
   * @param {AnswerRequest} answerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public answer(answerRequest: AnswerRequest, options?: any) {
    return QuizApiFp(this.configuration)
      .answer(answerRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Add or update a list of answers
   * @summary Answers
   * @param {AnswersRequest} answersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public answers(answersRequest: AnswersRequest, options?: any) {
    return QuizApiFp(this.configuration)
      .answers(answersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Add or update a list of answers from blue poop quiz. This specifically kicks off a background task to calculate diet score and gut twin. Mailchimp is not updated through this endpoint.
   * @summary Blue Poop Calc Results
   * @param {AnswersRequest} answersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public bluePoopCalcResults(answersRequest: AnswersRequest, options?: any) {
    return QuizApiFp(this.configuration)
      .bluePoopCalcResults(answersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Add or update a list of answers without re-running diet score or gut twin calcs. Mailchimp will be updated with new answers and results. The background task does a check to see if all required Mailchimp fields are present - if not, it waits for 5 seconds, then sends whatever is available at that time.
   * @summary Blue Poop Follow Up Answers
   * @param {AnswersRequest} answersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public bluePoopFollowUpAnswers(answersRequest: AnswersRequest, options?: any) {
    return QuizApiFp(this.configuration)
      .bluePoopFollowUpAnswers(answersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Register or update a user to a known user id
     * @summary Register
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (registrationRequest: RegistrationRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'registrationRequest' is not null or undefined
      assertParamExists('register', 'registrationRequest', registrationRequest)
      const localVarPath = `/user/register`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Register or update a user to a known user id for Blue Poop quiz
     * @summary Register Blue Poop
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerBluePoop: async (registrationRequest: RegistrationRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'registrationRequest' is not null or undefined
      assertParamExists('registerBluePoop', 'registrationRequest', registrationRequest)
      const localVarPath = `/user/register-blue-poop`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     * Register or update a user to a known user id
     * @summary Register
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      registrationRequest: RegistrationRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(registrationRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Register or update a user to a known user id for Blue Poop quiz
     * @summary Register Blue Poop
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerBluePoop(
      registrationRequest: RegistrationRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerBluePoop(registrationRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     * Register or update a user to a known user id
     * @summary Register
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(registrationRequest: RegistrationRequest, options?: any): AxiosPromise<RegistrationResponse> {
      return localVarFp.register(registrationRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Register or update a user to a known user id for Blue Poop quiz
     * @summary Register Blue Poop
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerBluePoop(registrationRequest: RegistrationRequest, options?: any): AxiosPromise<RegistrationResponse> {
      return localVarFp.registerBluePoop(registrationRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Register or update a user to a known user id
   * @summary Register
   * @param {RegistrationRequest} registrationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public register(registrationRequest: RegistrationRequest, options?: any) {
    return UserApiFp(this.configuration)
      .register(registrationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Register or update a user to a known user id for Blue Poop quiz
   * @summary Register Blue Poop
   * @param {RegistrationRequest} registrationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public registerBluePoop(registrationRequest: RegistrationRequest, options?: any) {
    return UserApiFp(this.configuration)
      .registerBluePoop(registrationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WebhookApi - axios parameter creator
 * @export
 */
export const WebhookApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Webhook called by Mailchimp on enabled events
     * @summary Mailchimp Webhook
     * @param {MailchimpWebookRequest} mailchimpWebookRequest
     * @param {string} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailchimpWebhook: async (
      mailchimpWebookRequest: MailchimpWebookRequest,
      userAgent?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mailchimpWebookRequest' is not null or undefined
      assertParamExists('mailchimpWebhook', 'mailchimpWebookRequest', mailchimpWebookRequest)
      const localVarPath = `/webhook/mailchimp`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (userAgent !== undefined && userAgent !== null) {
        localVarHeaderParameter['user-agent'] = String(userAgent)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(mailchimpWebookRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Mailchimp Webhook Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailchimpWebhookHealthcheck: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/webhook/mailchimp`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebhookApi - functional programming interface
 * @export
 */
export const WebhookApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebhookApiAxiosParamCreator(configuration)
  return {
    /**
     * Webhook called by Mailchimp on enabled events
     * @summary Mailchimp Webhook
     * @param {MailchimpWebookRequest} mailchimpWebookRequest
     * @param {string} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailchimpWebhook(
      mailchimpWebookRequest: MailchimpWebookRequest,
      userAgent?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailchimpWebhook(
        mailchimpWebookRequest,
        userAgent,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Mailchimp Webhook Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailchimpWebhookHealthcheck(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailchimpWebhookHealthcheck(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WebhookApi - factory interface
 * @export
 */
export const WebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WebhookApiFp(configuration)
  return {
    /**
     * Webhook called by Mailchimp on enabled events
     * @summary Mailchimp Webhook
     * @param {MailchimpWebookRequest} mailchimpWebookRequest
     * @param {string} [userAgent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailchimpWebhook(
      mailchimpWebookRequest: MailchimpWebookRequest,
      userAgent?: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .mailchimpWebhook(mailchimpWebookRequest, userAgent, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Mailchimp Webhook Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailchimpWebhookHealthcheck(options?: any): AxiosPromise<any> {
      return localVarFp.mailchimpWebhookHealthcheck(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
export class WebhookApi extends BaseAPI {
  /**
   * Webhook called by Mailchimp on enabled events
   * @summary Mailchimp Webhook
   * @param {MailchimpWebookRequest} mailchimpWebookRequest
   * @param {string} [userAgent]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookApi
   */
  public mailchimpWebhook(mailchimpWebookRequest: MailchimpWebookRequest, userAgent?: string, options?: any) {
    return WebhookApiFp(this.configuration)
      .mailchimpWebhook(mailchimpWebookRequest, userAgent, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Mailchimp Webhook Healthcheck
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookApi
   */
  public mailchimpWebhookHealthcheck(options?: any) {
    return WebhookApiFp(this.configuration)
      .mailchimpWebhookHealthcheck(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
