import React from 'react'
import { appConfig } from '../../../../appConfig'
import { PoopPersonalityType } from '../../../../redux/quiz/types'
import TagManager from 'react-gtm-module'
import { socialSharingDataLayer } from '../../gtm'

export interface DownloadImageIconProps {
  poopPersonalityType: PoopPersonalityType
}

export const DownloadImageIcon: React.FC<DownloadImageIconProps> = ({ poopPersonalityType }) => {
  const supportsDownload = () => {
    return 'download' in document.createElement('a')
  }

  const sendToGTM = () => {
    TagManager.dataLayer({
      dataLayer: socialSharingDataLayer('Download'),
    })
  }

  return (
    <>
      {supportsDownload() && (
        <a download href={`${appConfig.publicUrl}/img/sharing/${poopPersonalityType}.png`} onClick={() => sendToGTM()}>
          <img
            src={`${appConfig.publicUrl}/img/social/download.png`}
            alt="Download"
            className="mx-auto max-w-social-icon sm:max-w-social-icon-desktop"
          />
        </a>
      )}
    </>
  )
}
