import { FormattedMessage } from 'react-intl'
import React from 'react'
import { appConfig } from '../../../../appConfig'
import { transitTimeComparator } from '../../../../data/transitTimeCompator'

interface TransitTimeGraphCardProps {
  transitTimeHrs: number
}

export const TransitTimeGraphCard: React.FC<TransitTimeGraphCardProps> = ({ transitTimeHrs }) => {
  return (
    <div className="text-center sm:max-w-transit-graph-card mx-auto">
      <h2 className="uppercase tracking-wider mb-3">
        <FormattedMessage id="quiz.transit_time_results.graph.title" />
      </h2>
      <p className="light mb-8 mx-auto">
        <FormattedMessage
          id="quiz.transit_time_results.graph.statement"
          values={{
            speed: transitTimeComparator(transitTimeHrs).speed,
          }}
        />
      </p>
      <img
        src={`${appConfig.publicUrl}/img/transit-graphs/transit-graph-${
          transitTimeComparator(transitTimeHrs).chart
        }.png`}
        className="w-transit-graph-width mx-auto"
      />
      <div className="max-w-results-width-mobile sm:max-w-transit-graph-card mx-auto mt-10 sm:mt-12 text-left">
        <p className="light">
          <FormattedMessage
            id={`quiz.transit_time_results.description.${transitTimeComparator(transitTimeHrs).speed}`}
            values={{ br: <br /> }}
          />
        </p>

        <p className="text-fineprint light mt-4 opacity-60 sm:text-center sm:mt-10">
          <FormattedMessage id="quiz.transit_time_results.disclaimer" />
        </p>
      </div>
    </div>
  )
}
