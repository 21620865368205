import { combineReducers } from 'redux'
import quiz from 'redux/quiz/slice'
import storage from './storage'
import { AnyAction, Reducer } from '@reduxjs/toolkit'

const appReducer = combineReducers({ quiz })

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'quiz/clearQuiz') {
    // for all keys defined in persistConfig(s)
    storage.removeItem('persist:root')

    state = {} as RootState
  }
  return appReducer(state, action)
}

export default rootReducer
export type RootState = ReturnType<typeof appReducer>
