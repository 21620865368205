const appEnv = process.env.REACT_APP_ENV || 'development'

export const __DEV__ = appEnv !== 'production'

export type AppConfig = {
  environment: string
  sentryDsn: string
  gtmId: string
  fbPixelId: string
  ipStackKey: string
  quizServiceApiBaseUri: string
  publicUrl: string
}

const BASE = {
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  gtmId: process.env.REACT_APP_GTM_ID || '',
  fbPixelId: process.env.REACT_APP_FB_PIXEL_ID || '',
  ipStackKey: process.env.REACT_APP_IP_STACK_KEY || '',
  publicUrl: process.env.PUBLIC_URL || '',
}

const DEVELOPMENT: AppConfig = {
  ...BASE,
  environment: 'development',
  quizServiceApiBaseUri: 'http://localhost',
}

const STAGING: AppConfig = {
  ...BASE,
  environment: 'stage',
  quizServiceApiBaseUri: 'https://quiz.services.stage.zoe.net',
}

const PRODUCTION: AppConfig = {
  ...BASE,
  environment: 'production',
  quizServiceApiBaseUri: 'https://quiz.services.prod.zoe.net',
}

export const appConfig: AppConfig =
  (appEnv === 'development' && DEVELOPMENT) ||
  (appEnv === 'stage' && STAGING) ||
  (appEnv === 'production' && PRODUCTION) ||
  DEVELOPMENT
