import React from 'react'
import { GutMicrobeIcon } from './GutMicrobeIcon'

interface GutMicrobesCardProps {
  microbe: any
}

export const GutMicrobesCard: React.FC<GutMicrobesCardProps> = ({ microbe }) => {
  return (
    <div
      className="p-8 max-w-microbe-card bg-results-microbe-card rounded-lg relative text-text-dark"
      style={{ color: '#24262B', margin: 'auto', minWidth: '327px', minHeight: '490px' }}
    >
      <GutMicrobeIcon
        present={true}
        number={microbe.number}
        nickname={microbe.nickname}
        showNickname={false}
        eyes={true}
      />
      <h3 className="font-bold text-2xl leading-8">{microbe.nickname}</h3>
      <p className="text-base-secondary text-footnote mb-4 opacity-90 ">
        <em>{microbe.scientificNameDisplay}</em>
      </p>
      <p className="text-base-secondary mb-6 text-fineprint text-base-tertiary light">{microbe.carouselSubheader}</p>
      <hr />
      <div className="text-base-secondary mt-6 text-left">
        <p className="text-base-secondary text-footnote light">{microbe.carouselDescription}</p>
      </div>
    </div>
  )
}
