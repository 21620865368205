import React from 'react'
import { appConfig } from '../../../../appConfig'

export interface BluePoopChallengeHashtagProps {
  darkText?: boolean
}

export const BluePoopChallengeHashtag: React.FC<BluePoopChallengeHashtagProps> = ({ darkText }) => {
  return (
    <div className="mx-auto max-w-info-width-mobile sm:max-w-results-width-desktop text-center">
      {/* <iconSVG /> */}
      <img
        src={`${appConfig.publicUrl}/img/logos/bluepoopchallenge.png`}
        alt="Blue Poop Challenge"
        className="mx-auto w-hashtag-blue-poop-challenge"
      />
      <p className={`mt-2 opacity-90 ${darkText ? 'text-text-dark' : ''}`}>
        #<strong>blue</strong>poop<strong>challenge</strong>
      </p>
    </div>
  )
}
