import React, { useMemo } from 'react'
import classNames from 'classnames'

export interface StageMarkerProps {
  index: number
  completed: boolean
}

export const StageMarker: React.FC<StageMarkerProps> = React.memo(({ index, completed }) => {
  const classes = classNames(
    'h-4',
    'w-4',
    'flex-shrink-0',
    'text-state-progress-marker',
    'font-semibold',
    'text-center',
    'border-2',
    'border-solid',
    'rounded-full',
    { 'border-predict text-white bg-predict': completed },
    { 'border-white text-base-secondary bg-white': !completed },
  )

  return (
    <div key={index} className={classes}>
      {index + 1}
    </div>
  )
})

export interface StageProgressBarProps {
  currentStep: number
  stepsInStage: number
  currentStage: number
  totalStages: number
}

export const StageProgressBar: React.FC<StageProgressBarProps> = React.memo(
  ({ currentStep, stepsInStage, currentStage, totalStages }) => {
    const indexes = useMemo(() => Array.from({ length: totalStages + 1 }, (_, i) => i), [totalStages])
    const progressInStage = currentStep / stepsInStage
    return (
      <div className="flex flex-row w-full sm:max-w-stage-progress-bar-width-desktop pt-58px px-8 h-6 items-center mx-auto">
        {indexes.map((i) => (
          <React.Fragment key={i}>
            <StageMarker index={i} completed={currentStage >= i || (i === currentStage + 1 && progressInStage === 1)} />
            {i !== totalStages && (
              <div className="h-2px w-full bg-white">
                <div
                  className="h-2px bg-predict"
                  style={{
                    // inline style as Tailwind width-fractions utility class is fragile
                    width:
                      i < currentStage ? '100%' : i === currentStage ? (progressInStage * 100).toFixed(2) + '%' : '0%',
                  }}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  },
)
