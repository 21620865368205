import React from 'react'
import { useField, useFormikContext } from 'formik'
import { FieldError } from './FieldError'

export interface RadioButtonProps extends React.ComponentProps<'input'> {
  label?: React.ReactNode
  name: string // needed for useField(props)
  clicked?: any // this doesn't seem right
}

export const RadioButtonRound: React.FC<RadioButtonProps> = ({ clicked, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const context = useFormikContext()
  const { setValue } = helpers

  const clickHandler = async () => {
    setValue(props.value)

    await context.validateForm({
      ...(context.values as any),
      [props.name]: props.value, // ensures validation with the new value
    })

    clicked ? clicked(props.value) : null
  }

  return (
    <div className={`flex-1 highlight-label-round-selected ${props.className}`}>
      <input
        type="radio"
        {...field}
        checked={field.value === props.value}
        className={`fixed pointer-events-none opacity-0 ${
          !field.value && field.value !== props.value ? 'indeterminate' : ''
        }`}
        onClick={clickHandler}
      />
      <label
        className="block p-4 rounded-full text-center bg-background-secondary hover:bg-button-secondary max-w-radio-button-round-max-width mx-auto"
        htmlFor={name}
        onClick={clickHandler}
      >
        {props.label || props.value}
      </label>
      {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
