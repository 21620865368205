import React from 'react'

import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import * as Yup from 'yup'

import { InputField } from 'components/core/fields/InputField'
import {
  MAX_HEIGHT_FEET,
  MAX_HEIGHT_INCHES,
  MAX_WEIGHT,
  MIN_HEIGHT_FEET,
  MIN_HEIGHT_INCHES,
  MIN_WEIGHT,
} from '../constants'
import { FormValidationSchema } from '../../screens/FormScreen'

export const HeightWeightForm: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <p className="light mb-2">
        <FormattedMessage id="quiz.height_weight.height_field.label" />
      </p>
      <div className="flex mb-4">
        <div className="mr-2 flex-1">
          <InputField
            name="height_ft"
            type="number"
            placeholder={intl.formatMessage({ id: 'feet.abbreviation' })}
            min={MIN_HEIGHT_FEET}
            max={MAX_HEIGHT_FEET}
          />
        </div>
        <div className="flex-1">
          <InputField
            name="height_in"
            type="number"
            placeholder={intl.formatMessage({ id: 'inches.abbreviation' })}
            min={MIN_HEIGHT_INCHES}
            max={MAX_HEIGHT_INCHES}
          />
        </div>
      </div>
      <InputField
        label={intl.formatMessage({ id: 'quiz.height_weight.weight_field.label' })}
        name="weight"
        type="number"
        step="0.1"
        placeholder={intl.formatMessage({ id: 'pounds.abbreviation' })}
        min={MIN_WEIGHT}
        max={MAX_WEIGHT}
      />
    </>
  )
}

export const validationSchema = (intl: IntlShape): FormValidationSchema => {
  return Yup.object({
    height_ft: Yup.number()
      .min(MIN_HEIGHT_FEET, `Your height must be above ${MIN_HEIGHT_FEET} ft ${MIN_HEIGHT_INCHES} in`)
      .max(MAX_HEIGHT_FEET, `Your height must be below ${MAX_HEIGHT_FEET} ft ${MAX_HEIGHT_INCHES} in`)
      .required(intl.formatMessage({ id: 'quiz.height_weight.height_field.error' })),
    height_in: Yup.number()
      .min(MIN_HEIGHT_INCHES, `Your height must be above ${MIN_HEIGHT_FEET} ft ${MIN_HEIGHT_INCHES} in`)
      .max(MAX_HEIGHT_INCHES, `This should be below 12 inches`)
      .when('height_ft', {
        is: (height_ft) => height_ft >= MIN_HEIGHT_FEET,
        then: Yup.number().required(intl.formatMessage({ id: 'quiz.height_weight.height_field.error' })),
      }),
    weight: Yup.number()
      .min(MIN_WEIGHT, `Your weight must equal or be above ${MIN_WEIGHT} lbs`)
      .max(MAX_WEIGHT, `Your weight must equal or be below ${MAX_WEIGHT} lbs`)
      .required(intl.formatMessage({ id: 'quiz.height_weight.weight_field.error' })),
  })
}
