import React from 'react'
import { appConfig } from '../../../../appConfig'
import { PoopPersonalityName, PoopPersonalityType } from '../../../../redux/quiz/types'
import { FormattedMessage } from 'react-intl'

export interface SharingAssetProps {
  poopPersonalityType: PoopPersonalityType
  poopPersonalityName: PoopPersonalityName
}

export const SharingAsset: React.FC<SharingAssetProps> = ({ poopPersonalityType, poopPersonalityName }) => {
  return (
    <div className="sm:flex sm:flex-row sm:items-start sm:justify-center">
      <img
        src={`${appConfig.publicUrl}/img/poop-cards/${poopPersonalityType}.png`}
        alt={poopPersonalityName}
        className="mx-auto sm:mx-0 sm:mr-2 mb-4 sm:mb-0 w-poop-card"
      />

      <div className="bg-grey pt-4 sm:py-6 pb-6 px-5 sm:px-6 rounded-2xl mx-auto sm:mx-0  max-w-info-width-mobile sm:min-h-140 sm:max-w-sharing-text-desktop">
        <p className="light text-base-secondary text-footnote sm:text-footnote-bold">
          {/*<FormattedMessage id={`quiz.poop_personality.sharing_text.${poopPersonalityType}`} />*/}
          <FormattedMessage id="quiz.poop_personality.sharing_text.join_challenge" />
        </p>
      </div>
    </div>
  )
}
