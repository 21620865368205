import React, { useMemo } from 'react'
import { ScreenProps } from '../types'
import { Button } from '../../core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
import { appConfig } from '../../../appConfig'
import { PoopPersonalityName } from '../../../redux/quiz/types'
import { FormattedMessage } from 'react-intl'
import { CopyForInstagramButton } from '../vBluePoo/components/CopyForInstagramButton'
import { LottieAnimation } from '../../core/lottie/LottieAnimation'
import mobileLongPress from '../../../images/animations/mobile-long-press.json'
import instagramInstructions from '../../../images/animations/instagram-instructions.json'
import { parseInstagramPageQueryParams } from '../webflow'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { POOP_PERSONALITIES } from '../vBluePoo/constants'

export interface InstagramScreenProps extends ScreenProps {
  subTitle: React.ReactNode
  animatedImage?: boolean
  instructions: React.ReactNode
  sharingText?: React.ReactNode
  buttonText: React.ReactNode
}

export const InstagramScreen: React.FC<InstagramScreenProps & RouteComponentProps> = ({
  subTitle,
  animatedImage,
  instructions,
  sharingText,
  buttonText,
  location,
}) => {
  const poopName =
    // @ts-ignore
    (location.state?.poopName as PoopPersonalityName) ||
    useMemo(() => parseInstagramPageQueryParams(location).poopName, [location])
  const poopPersonalityName = poopName
  const poopPersonalityType = POOP_PERSONALITIES[poopPersonalityName].poopPersonalityType
  const history = useHistory()

  return (
    <>
      <LogoHeader />
      <main className="flex justify-center pt-8">
        <div className="text-center max-w-info-width-mobile sm:max-w-info-width-desktop">
          <h3 className="light text-base-secondary">
            <FormattedMessage id="quiz.instagram.title" />
          </h3>
          <p className="text-xs leading-8 text-base-quaternary">{subTitle}</p>
          {!sharingText ? (
            <div className="mt-4 mb-8 px-2 max-w-sharing-asset">
              <img
                src={`${appConfig.publicUrl}/img/sharing/${poopPersonalityType}.png`}
                className="mx-auto rounded-xl"
              />
            </div>
          ) : (
            <div className="max-w-sharing-asset mt-4 mb-8 px-2">
              <LottieAnimation source={instagramInstructions} width={327} height={327} />
            </div>
          )}
          {animatedImage && (
            <div className="mb-2">
              <LottieAnimation source={mobileLongPress} width={76} height={60} />
            </div>
          )}
          <p className="text-xl light text-base-secondary max-w-instagram-instructions mx-auto mb-8">{instructions}</p>
          {sharingText && (
            <>
              <div className="p-4 bg-grey mt-8 rounded-2xl relative">
                <div
                  className={`w-0 h-0 border-t-14 border-grey right-section-arrow absolute`}
                  style={{
                    borderLeft: '14px solid transparent',
                    borderRight: '14px solid transparent',
                    top: 92,
                  }}
                ></div>
                <p className="text-base-secondary text-sm leading-5 light">
                  <FormattedMessage
                    id="quiz.instagram_2.sharing_text"
                    values={{ br: <br />, poop_personality: poopPersonalityName }}
                  />
                </p>
              </div>
            </>
          )}
          <div className="mt-6 mb-12 text-center">
            {/* TODO: make more robust */}
            {sharingText ? (
              <CopyForInstagramButton poopPersonalityName={poopPersonalityName} buttonText={buttonText} />
            ) : (
              <Button
                onClick={() => {
                  history.push({ pathname: '/instagram-2', state: { poopName: poopPersonalityName } })
                }}
                altbackground="background"
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </main>
    </>
  )
}
