import React from 'react'

interface TransitTimeHoursMinsProps {
  transitTimeHours: number
}

export const TransitTimeHoursMins: React.FC<TransitTimeHoursMinsProps> = ({ transitTimeHours }) => {
  const hours = Math.floor(transitTimeHours)
  const minutes = Math.floor((transitTimeHours - hours) * 60)
  return (
    <div>
      <span className="text-headline-numbers">{hours}</span>
      {/* TODO: fix alignment of colon */}
      <span className="text-xl"> HRS : </span>
      <span className="text-headline-numbers">{minutes}</span>
      <span className="text-xl"> MIN </span>
    </div>
  )
}
