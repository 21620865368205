import { InsulinSensitivityAndSecretion } from './textComponents/InsulinSensitivityAndSecretion'
import { PufaAndInsulinSecretion } from './textComponents/PufaAndInsulinSecretion'
import { CvdAndInsulinSensitivity } from './textComponents/CvdAndInsulinSensitivity'
import { PufaAndInflammation } from './textComponents/PufaAndInflammation'
import { InsulinAndInflammation } from './textComponents/InsulinAndInflammation'
import { InsulinSensitivityAndLevels } from './textComponents/InsulinSensitivityAndLevels'
import Lazypoops from '../../../images/animations/poop-animations/Lazypoops.json'
import PartyPooper from '../../../images/animations/poop-animations/PartyPooper.json'
import Pooptastic from '../../../images/animations/poop-animations/Pooptastic.json'
import Pooptastrophe from '../../../images/animations/poop-animations/Pooptastrophe.json'
import ScaredyPoop from '../../../images/animations/poop-animations/ScaredyPoop.json'
import SirPoopsALot from '../../../images/animations/poop-animations/SirPoopsALot.json'
import StormPooper from '../../../images/animations/poop-animations/StormPooper.json'
import SuperPooper from '../../../images/animations/poop-animations/SuperPooper.json'
import Thunderpoop from '../../../images/animations/poop-animations/Thunderpoop.json'

export const MIN_HEIGHT_FEET = 1
export const MAX_HEIGHT_FEET = 13

export const MIN_HEIGHT_INCHES = 0
export const MAX_HEIGHT_INCHES = 11

export const MIN_WEIGHT = 40
export const MAX_WEIGHT = 700

export const GTM_QUESTION_KEY_ALLOW_LIST = ['country', 'state']

// For StageProgressBar
export const TOTAL_STAGES = 3
export const STAGE_INFO = {
  transitTime: { currentStage: 0, stepsInStage: 5 },
  body: { currentStage: 1, stepsInStage: 9 },
  diet: { currentStage: 2, stepsInStage: 3 },
}

export const POOP_PERSONALITIES = {
  'Storm Pooper': { poopPersonalityType: 'stormpooper', animatedFilename: StormPooper },
  Thunderpoop: { poopPersonalityType: 'thunderpoop', animatedFilename: Thunderpoop },
  Pooptastrophe: { poopPersonalityType: 'pooptastrophe', animatedFilename: Pooptastrophe },
  'Party Pooper': { poopPersonalityType: 'partypooper', animatedFilename: PartyPooper },
  'Sir Poops A Lot': { poopPersonalityType: 'sirpoopsalot', animatedFilename: SirPoopsALot },
  'Super Pooper': { poopPersonalityType: 'superpooper', animatedFilename: SuperPooper },
  Pooptastic: { poopPersonalityType: 'pooptastic', animatedFilename: Pooptastic },
  Lazypoops: { poopPersonalityType: 'lazypoops', animatedFilename: Lazypoops },
  'Scaredy Poop': { poopPersonalityType: 'scaredypoop', animatedFilename: ScaredyPoop },
} as const

export const GOOD_MICROBES = [
  {
    number: 1,
    nickname: 'Billy',
    scientificName: 'Bifidobacterium animalis',
    scientificNameDisplay: 'Bifidobacterium animalis',
    description: InsulinSensitivityAndSecretion,
  },
  {
    number: 2,
    nickname: 'Claude',
    scientificName: 'Clostridium sp CAG_167',
    scientificNameDisplay: 'Clostridium sp. CAG:167',
    description: InsulinSensitivityAndSecretion,
  },
  {
    number: 3,
    nickname: 'Ellie',
    scientificName: 'Eubacterium eligens',
    scientificNameDisplay: 'Eubacterium eligens',
    description: PufaAndInsulinSecretion,
    carousel: true,
    carouselSubheader: 'Associated with lower insulin secretion & higher polyunsaturated fat (PUFA) levels.',
    carouselDescription:
      'ZOE’s groundbreaking research has shown that gut microbes can influence your blood sugar and fat responses after eating. ',
  },
  {
    number: 4,
    nickname: 'Felicia',
    scientificName: 'Faecalibacterium prausnitzii',
    scientificNameDisplay: 'Faecalibacterium prausnitzii',
    description: PufaAndInsulinSecretion,
    carousel: true,
    carouselSubheader: 'Associated with lower insulin secretion & higher polyunsaturated fat (PUFA) levels.',
    carouselDescription:
      'You are more microbe than human! You have 10 times as many microbial cells in your gut than there are cells' +
      ' in your whole body – totaling around 100 trillion microbes!',
  },
  {
    number: 5,
    nickname: 'Finn',
    scientificName: 'Firmicutes bacterium CAG_170',
    scientificNameDisplay: 'Firmicutes bacterium CAG:170',
    description: CvdAndInsulinSensitivity,
  },
  {
    number: 6,
    nickname: 'Freddy',
    scientificName: 'Firmicutes bacterium CAG_95',
    scientificNameDisplay: 'Firmicutes bacterium CAG:95',
    description: InsulinSensitivityAndSecretion,
  },
  {
    number: 7,
    nickname: 'Hannah',
    scientificName: 'Haemophilus parainfluenzae',
    scientificNameDisplay: 'Haemophilus parainfluenzae',
    description: InsulinSensitivityAndSecretion,
  },
  {
    number: 8,
    nickname: 'Oscar',
    scientificName: 'Oscillibacter sp 57_20',
    scientificNameDisplay: 'Oscillibacter sp. 57_20',
    description: InsulinSensitivityAndSecretion,
    carousel: true,
    carouselSubheader: 'Associated with higher insulin sensitivity & lower insulin secretion.',
    carouselDescription:
      'Did you know? "Good" gut bugs transform the foods you eat into thousands of beneficial metabolites, enzymes, and vitamins that your body can’t produce itself.',
  },
  {
    number: 9,
    nickname: 'Otis',
    scientificName: 'Oscillibacter sp PC13',
    scientificNameDisplay: 'Oscillibacter sp. PC13',
    description: PufaAndInsulinSecretion,
  },
  {
    number: 10,
    nickname: 'Patrick',
    scientificName: 'Prevotella copri',
    scientificNameDisplay: 'Prevotella copri',
    description: InsulinSensitivityAndSecretion,
  },
  {
    number: 11,
    nickname: 'Rumi',
    scientificName: 'Romboutsia ilealis',
    scientificNameDisplay: 'Romboutsia ilealis',
    description: PufaAndInflammation,
  },
  {
    number: 12,
    nickname: 'Rosie',
    scientificName: 'Roseburia sp CAG_182',
    scientificNameDisplay: 'Roseburia sp. CAG:182',
    description: PufaAndInsulinSecretion,
  },
  {
    number: 13,
    nickname: 'Valentina',
    scientificName: 'Veillonella infantium',
    scientificNameDisplay: 'Veillonella infantium',
    description: PufaAndInflammation,
  },
  {
    number: 14,
    nickname: 'Veronica',
    scientificName: 'Veillonella dispar',
    scientificNameDisplay: 'Veillonella dispar',
    description: InsulinAndInflammation,
  },
  {
    number: 15,
    nickname: 'Violet',
    scientificName: 'Veillonella atypica',
    scientificNameDisplay: 'Veillonella atypica',
    description: InsulinSensitivityAndLevels,
  },
] as const

// For country select dropdown
// https://craftcms.stackexchange.com/questions/4510/display-list-of-countries-in-form-using-a-dropdown
export const COUNTRIES = [
  { label: 'Please select one of the options', value: '' },
  { label: 'United States', value: 'US' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Aland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People's Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
  { label: 'Other', value: 'Other' },
]

// https://gist.github.com/mshafrir/2646763
export const US_STATES = [
  { label: 'Please select one of the options', value: '' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona ', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California ', value: 'CA' },
  { label: 'Colorado ', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia ', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'Other', value: 'Other' },
]
