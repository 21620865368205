import React from 'react'
import { FormattedMessage } from 'react-intl'

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <FormattedMessage id="quiz.email.privacy_statement" />
      <br />
      <br />
      We follow strict European data privacy laws. Our{' '}
      <a href="https://joinzoe.com/bluepoop/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      explains how we protect your data.
    </>
  )
}
