import React from 'react'
import { PoopPersonalityType } from '../../../../redux/quiz/types'
import { FormattedMessage } from 'react-intl'
import { appConfig } from '../../../../appConfig'
import { BluePoopChallengeHashtag } from './BluePoopChallengeHashtag'

export interface GutHealthTipProps {
  poopPersonalityType: PoopPersonalityType
}

export const GutHealthTip: React.FC<GutHealthTipProps> = ({ poopPersonalityType }) => {
  const backgroundImage = `gut-tip-${poopPersonalityType}`
  const desktopBackgroundImage = `gut-tip-${poopPersonalityType}-desktop`

  return (
    <div
      className={`h-full bg-no-repeat bg-center bg-cover 
      ${backgroundImage ? 'bg-' + backgroundImage : ''}
      ${desktopBackgroundImage ? 'sm:bg-' + desktopBackgroundImage : ''}
      flex justify-center items-center
      `}
    >
      <div className="bg-white text-base-secondary max-w-info-width-mobile mx-auto text-center sm:max-w-results-width-desktop px-6 pb-8 sm:pb-12 inset-auto">
        <div className="bg-zoe-active mx-auto pt-10" style={{ height: '66px', width: '55px' }}>
          <img
            src={`${appConfig.publicUrl}/img/logos/zoe-logo.svg`}
            alt="ZOE logo"
            className="mx-auto"
            style={{ width: '38px' }}
          />
        </div>
        <h2 className="uppercase tracking-wider mt-4 mb-1 sm:mt-8 sm:mb-2 text-base-secondary">
          <FormattedMessage id="quiz.summary.tip.title" />
        </h2>
        <p className="text-light-grey opacity-90 light mb-4 sm:mb-6">
          <FormattedMessage id="quiz.summary.tip.subtitle" />
        </p>
        <h4 className="light text-base-secondary text-xl leading-26px mb-3 sm:mb-12">
          <FormattedMessage id={`quiz.poop_personality.work_on.${poopPersonalityType}`} />
        </h4>
        <BluePoopChallengeHashtag darkText={true} />
      </div>
    </div>
  )
}
