import React from 'react'
import { FormattedMessage } from 'react-intl'
import { appConfig } from '../../../../appConfig'
import { GutMicrobesCard } from './GutMicrobesCard'
import { GutMicrobesGrid } from './GutMicrobesGrid'
import { GoodMicrobesScientificName } from '../../../../redux/quiz/types'
import { BluePoopChallengeHashtag } from './BluePoopChallengeHashtag'
import { Polaroid } from './Polaroid'
import { GOOD_MICROBES } from '../constants'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

export interface GutMicrobesResultProps {
  backgroundColour: string
  twinName: string
  twinGender: string
  twinHeightFt: number
  twinHeightIn: number
  twinAge: number
  twinWeightLbs: number
  twinGoodBugsNumber: number
  twinGoodBugsNames: GoodMicrobesScientificName[]
}

export const GutMicrobesResult: React.FC<GutMicrobesResultProps> = ({
  backgroundColour,
  twinName,
  twinAge,
  twinGender,
  twinGoodBugsNumber,
  twinGoodBugsNames,
}) => {
  const carouselResponsive = {
    mobile: {
      breakpoint: { max: 1024, min: 0 },
      items: 1,
    },
  }
  return (
    <div className="flex flex-col w-full">
      <div className={`w-full bg-${backgroundColour}`}>
        <div className="mx-auto max-w-info-width-mobile sm:max-w-results-width-desktop text-center pt-6 pb-16">
          <div className="mb-10 mx-auto">
            <GutMicrobesGrid
              twinName={twinName}
              twinGoodBugsNumber={twinGoodBugsNumber}
              twinGoodBugsNames={twinGoodBugsNames}
            />
          </div>
          <BluePoopChallengeHashtag />
          <p className="text-fineprint light mt-6 opacity-60 sm:text-center">
            <FormattedMessage id="quiz.results.gutmicrobes.disclaimer" />
          </p>
        </div>
      </div>
      <div className="pb-20 w-full bg-results-background-deeper-blue relative">
        <div
          className="w-0 h-0 border-b-14 border-results-background-deeper-blue right-section-arrow absolute"
          style={{
            borderLeft: '14px solid transparent',
            borderRight: '14px solid transparent',
            top: -14,
          }}
        ></div>

        <div
          className="mx-auto text-center max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto pt-8 sm:bg-results-background-deeper-blue
          sm:rounded-2xl sm:pt-12"
        >
          <img
            src={`${appConfig.publicUrl}/img/microbes/party.png`}
            alt="Microbes"
            className="mx-auto mt-6 mb-12 w-gut-microbes-party"
          />

          <div className="text-center max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto">
            <h2 className="uppercase tracking-wider mb-3">
              <FormattedMessage id="quiz.results.gutmicrobes.header_1" />
            </h2>
            <p className="light mx-auto">
              <FormattedMessage id="quiz.results.gutmicrobes.header_2" />
            </p>
          </div>
          <div className="pt-16 pb-4 relative m-auto" style={{ height: '220px', width: '340px' }}>
            <div className="absolute" style={{ left: '70px' }}>
              <Polaroid twinGender={twinGender} twinName={twinName} twinAge={twinAge} rotation={'-rotate-3'} />
            </div>
            <img
              src={`${appConfig.publicUrl}/img/icons/polaroid-icons.png`}
              alt="Microbe icons"
              className="absolute"
              width={'340px'}
              style={{ top: '90px', left: '-20px' }}
            />
          </div>
          <h2 className="mt-16 mb-4 uppercase">
            <FormattedMessage id="quiz.results.gutmicrobes.header_3" />
          </h2>
          <p className="light">
            <FormattedMessage id="quiz.results.gutmicrobes.header_4" values={{ br: <br /> }} />
          </p>
          <div className="mt-8 pb-20 sm:mt-20 sm:pb-12" style={{ minHeight: '190px' }}>
            <div className="bg-results-background-deep-blue rounded-2xl py-4 px-6 w-small-speech-bubble relative mx-auto">
              <p className="light text-center">
                <FormattedMessage id="quiz.results.fun_fact.these_scientists" />
              </p>
              <div
                className={`w-0 h-0 border-t-14 border-results-background-deep-blue left-speech-arrow-left absolute`}
                style={{
                  borderLeft: '14px solid transparent',
                  bottom: -14,
                }}
              ></div>

              <img
                src={`${appConfig.publicUrl}/img/poop/scaredypoop.png`}
                alt="Scaredy Poop says these scientists eh"
                className="w-poop-personality-character-side absolute mx-auto"
                style={{ top: 60, right: 20 }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 pb-6 w-full bg-results-background-deep-blue">
        <div className="mx-auto text-center text-center m-auto max-w-info-width-mobile sm:max-w-results-width-desktop">
          <h2 className="mt-10 mb-8 text-center max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto">
            <FormattedMessage id="quiz.results.gutmicrobes.header_6" />
          </h2>
          {/*mobile*/}
          <div className="lg:hidden mb-6">
            <Carousel responsive={carouselResponsive}>
              {GOOD_MICROBES.filter((microbe: any) => microbe.carousel).map((microbe: any, i: number) => (
                <GutMicrobesCard key={i} microbe={microbe} />
              ))}
            </Carousel>
          </div>
          {/*desktop*/}
          <div className="hidden lg:flex flex-row" style={{ justifyContent: 'center' }}>
            {GOOD_MICROBES.filter((microbe: any) => microbe.carousel).map((microbe: any, i: number) =>
              i < 3 ? (
                <div className="m-4" key={i}>
                  <GutMicrobesCard microbe={microbe} />
                </div>
              ) : null,
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
