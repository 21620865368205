import React, { useCallback } from 'react'
import { ScreenProps } from '../types'
import { Button } from '../../core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
import { Answer } from '../../../redux/quiz/slice'

export interface InfoOnlyScreenProps extends ScreenProps {
  backgroundColour?: string
  headerBar?: React.ReactNode
  sectionTitle?: React.ReactNode
  screenTitle?: React.ReactNode
  description?: React.ReactNode
  buttonText?: React.ReactNode
  transitTimeToBeSubmitted?: Answer[]
}

export const InfoOnlyScreen: React.FC<InfoOnlyScreenProps> = ({
  backgroundColour,
  headerBar,
  sectionTitle,
  screenTitle,
  description,
  buttonText,
  transitTimeToBeSubmitted,
  onNextClicked,
}) => {
  const nextPageHandler = useCallback(() => {
    transitTimeToBeSubmitted ? onNextClicked(transitTimeToBeSubmitted) : onNextClicked()
  }, [onNextClicked])

  return (
    <div
      className={`h-screen
      bg-${backgroundColour ? backgroundColour : 'background'} 
      bg-no-repeat bg-auto 
      `}
      style={{ backgroundPosition: 'bottom' }}
    >
      <LogoHeader />
      {headerBar}
      <main
        className={`flex justify-center min-h-info-only-with-progress-bar bg-${
          backgroundColour ? backgroundColour : 'background'
        }`}
      >
        <div className="mt-10 sm:mt-20 mx-8 max-w-info-width-mobile sm:max-w-info-width-desktop sm:text-center flex flex-col justify-between">
          <div>
            {sectionTitle && <p className="text-base-primary light sm:text-lg mb-8 sm:mb-6 ">{sectionTitle}</p>}
            <h3 className="mb-10 light">{screenTitle}</h3>
            {description && <p className="light">{description}</p>}
          </div>
          <div className="mt-8 sm:mt-40 mb-32 text-center">
            <Button onClick={nextPageHandler}>{buttonText}</Button>
          </div>
        </div>
      </main>
    </div>
  )
}
