import { appConfig } from '../../../../appConfig'
import React from 'react'

interface BioStatProps {
  icon: string
  name: string
  result: string | number
}

export const BioStat: React.FC<BioStatProps> = ({ icon, name, result }) => {
  return (
    <div className="flex flex-col">
      <img src={`${appConfig.publicUrl}/img/icons/${icon}.svg`} alt="Bio stat icon" className="mx-auto mb-2" />
      <p className="light text-footnote mb-2px">{name}</p>
      <h4>{result}</h4>
    </div>
  )
}

interface GutTwinBioStatsProps {
  gender: string
  heightFt: number
  heightIn: number
  age: number
  weightLbs: number
}

export const GutTwinBioStats: React.FC<GutTwinBioStatsProps> = ({ gender, heightFt, heightIn, age, weightLbs }) => {
  const height = heightFt + 'ft ' + heightIn + 'in'
  const weight = weightLbs + ' lbs'
  const capitalisedGender = gender.charAt(0).toUpperCase() + gender.slice(1)

  return (
    <div className="flex flex-row justify-between max-w-gut-twin-bio-stats mx-auto">
      <BioStat icon="gut-twin-gender" name="Gender" result={capitalisedGender} />
      <div className="mt-6">
        <BioStat icon="gut-twin-height" name="Height" result={height} />
      </div>
      <div className="mt-6">
        <BioStat icon="gut-twin-age" name="Age" result={age} />
      </div>
      <BioStat icon="gut-twin-weight" name="Weight" result={weight} />
    </div>
  )
}
