import * as queryString from 'query-string'
import * as H from 'history'

import { ResultsPageQueryParams, InstagramPageQueryParams } from 'redux/quiz/types'

export const parseResultsPageQueryParams = (location: H.Location): ResultsPageQueryParams =>
  queryString.parse(location.search + location.hash) as ResultsPageQueryParams

export const parseInstagramPageQueryParams = (location: H.Location): InstagramPageQueryParams =>
  queryString.parse(location.search + location.hash) as InstagramPageQueryParams
