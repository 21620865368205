import React from 'react'

export const ResultsError: React.FC = () => (
  <div className="text-center light">
    <h4>We’re sorry, something went wrong. This might be due to a technical error.</h4>
    <h4 className="mt-6">
      Please try again or get in touch with our team at{' '}
      <a href="mailto:bluepoopchallenge@joinzoe.com">bluepoopchallenge@joinzoe.com</a> so we can investigate this.
    </h4>
  </div>
)
