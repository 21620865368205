import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { appConfig } from 'appConfig'
import { BluepoopApi, Configuration, QuizApi } from 'clients/quiz-service'
import { RootState } from 'redux/rootReducer'
import { Quiz, QuizVBluePoo, QuizVersion } from './types'
import { v4 as uuid } from 'uuid'
import { AppDispatch } from 'App'

export type UpdateQuizAction = {
  quiz: Partial<QuizVBluePoo>
  version: 'vBluePoo'
}

export type UpdateQuizWithIdAction = {
  quiz: Partial<QuizVBluePoo>
  version: 'vBluePoo'
  id: string
}

const config = new Configuration()
export const quizApi = new QuizApi(config, appConfig.quizServiceApiBaseUri)
export const bluePoopApi = new BluepoopApi(config, appConfig.quizServiceApiBaseUri)

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    id: uuid(),
    vBluePoo: {},
  } as Quiz,
  reducers: {
    updateQuiz(state, action: PayloadAction<UpdateQuizAction>) {
      const { quiz, version } = action.payload
      return {
        ...state,
        [version]: {
          ...state[version],
          ...quiz,
        },
      }
    },
    updateQuizWithId(state, action: PayloadAction<UpdateQuizWithIdAction>) {
      const { quiz, version, id } = action.payload
      return {
        ...state,
        id,
        [version]: {
          ...state[version],
          ...quiz,
        },
      }
    },
    clearQuiz() {
      //  Clearing redux state and localForage -> happens in rootReducer.ts
    },
  },
})

// Actions
export const { updateQuiz, updateQuizWithId, clearQuiz } = quizSlice.actions

// Async Actions

export type QuestionKey = keyof QuizVBluePoo

export interface Answer {
  question_key: QuestionKey
  answer: string | number | string[]
}

export interface Answers {
  quiz_version: QuizVersion
  answers: Answer[]
}

export interface ResultsRequest {
  quiz_version: QuizVersion
  user_id: string
}

// Helpers

const mapAnswersToQuizUpdate = (answers: Answer[]) =>
  answers.reduce<Partial<QuizVBluePoo>>((result, { question_key, answer }) => {
    // Redux needs state vars to be serializable
    const formattedAnswer = answer instanceof Date ? answer.toDateString() : answer
    return Object.assign(result, { [question_key]: formattedAnswer })
  }, {})

const sanitiseAnswersForRequest = (answers: Answer[]) =>
  // quiz-service answer column must be a string
  answers.map(({ question_key, answer }) => ({
    question_key,
    answer: answer === 'string' ? answer : answer.toString(),
  }))

const mapStateToAnswersArrayForDb = (allAnswers: QuizVBluePoo) => {
  const formattedAnswers = []
  for (const [key, value] of Object.entries(allAnswers)) {
    if (key !== 'email') {
      // quiz-service answer column must be a string
      formattedAnswers.push({ question_key: key, answer: value === 'string' ? value : value.toString() })
    }
  }
  return formattedAnswers
}

// Thunk actions

// called onFormSubmit and when transit time inputted direct - only updates Redux state
export const updateAnswersInState = createAsyncThunk<unknown, Answers, { dispatch: AppDispatch; state: RootState }>(
  'quiz/updateAnswersInState',
  async ({ quiz_version, answers }: Answers, { dispatch, getState }) => {
    await dispatch(
      updateQuiz({
        quiz: mapAnswersToQuizUpdate(answers),
        version: quiz_version,
      }),
    )

    return getState().quiz
  },
)

// only called on FFQ screen - runs data science calcs in quiz-service. All answers in state sent in a batch.
export const saveAndCalculateResults = createAsyncThunk<unknown, Answers, { dispatch: AppDispatch; state: RootState }>(
  'quiz/saveAndCalculateResults',
  async ({ quiz_version, answers }: Answers, { dispatch, getState }) => {
    await dispatch(
      updateQuiz({
        quiz: mapAnswersToQuizUpdate(answers),
        version: quiz_version,
      }),
    )

    try {
      const user_id: string = getState().quiz.id
      const allAnswersInState = getState().quiz[quiz_version]

      await quizApi.bluePoopCalcResults({
        user_id,
        quiz_version,
        answers: mapStateToAnswersArrayForDb(allAnswersInState),
      })
    } catch (err) {
      console.error(`Failed to submit answer: ${err}`)
    }

    return getState().quiz
  },
)

// called on all screens after email screen i.e. science consent - only sends that screen's
// answers to quiz-service to be saved. Results not recalculated.
export const updateAndSaveAnswers = createAsyncThunk<unknown, Answers, { dispatch: AppDispatch; state: RootState }>(
  'quiz/updateStateAndSaveAnswers',
  async ({ quiz_version, answers }: Answers, { dispatch, getState }) => {
    await dispatch(
      updateQuiz({
        quiz: mapAnswersToQuizUpdate(answers),
        version: quiz_version,
      }),
    )

    try {
      const user_id: string = getState().quiz.id

      await quizApi.bluePoopFollowUpAnswers({
        user_id,
        quiz_version,
        answers: sanitiseAnswersForRequest(answers),
      })
    } catch (err) {
      console.error(`Failed to submit answer: ${err}`)
    }

    return getState().quiz
  },
)

// only called on 1st results screen
export const getResults = createAsyncThunk<unknown, ResultsRequest, { dispatch: AppDispatch; state: RootState }>(
  'quiz/results',
  async ({ quiz_version, user_id }: ResultsRequest, { dispatch, getState }) => {
    try {
      const id = user_id === '' ? getState().quiz.id : user_id
      const { data } = await bluePoopApi.results(id)
      await dispatch(
        updateQuiz({
          // if typescript fails, need to update ResultsResponse type for poop_personality that's autogenerated by
          // OpenAPI
          quiz: data,
          version: quiz_version,
        }),
      )
    } catch (err) {
      console.error(`Could not retrieve Blue Poop results: ${err}`)
    }

    return getState().quiz
  },
)

// Selectors
const s = (state: RootState) => state.quiz

export const selectQuiz = (state: RootState): Quiz => s(state)

export default quizSlice.reducer
