import React from 'react'
import { PoopPersonalityName } from '../../../../redux/quiz/types'
import { SharingAsset } from './SharingAsset'
import { SharingLinks } from './SharingLinks'
import { POOP_PERSONALITIES } from '../constants'
import { FormattedMessage } from 'react-intl'

interface SharingModuleProps {
  poopPersonalityName: PoopPersonalityName
}

export const SharingModule: React.FC<SharingModuleProps> = ({ poopPersonalityName }) => {
  const poopPersonalityType = POOP_PERSONALITIES[poopPersonalityName]?.poopPersonalityType

  return (
    <>
      {/*mobile*/}
      <div className="bg-sharing-module-background bg-no-repeat bg-contain bg-top bg-white text-base-secondary text-center sm:hidden px-2 pt-10 pb-12 w-full">
        <h2 className="mb-2 uppercase tracking-widest text-white">Share</h2>
        <h2 className="mb-12 text-white">
          <FormattedMessage id="quiz.poop_personality.sharing_text.results" />
        </h2>
        <SharingAsset poopPersonalityType={poopPersonalityType} poopPersonalityName={poopPersonalityName} />
        <div className="mt-8">
          <SharingLinks poopPersonalityType={poopPersonalityType} poopPersonalityName={poopPersonalityName} />
        </div>
      </div>

      {/*desktop*/}
      <div className="bg-white rounded-2xl text-base-secondary mx-auto p-8 pt-16 pb-20 hidden sm:block max-w-sharing-module-desktop">
        <h2 className="text-base-secondary uppercase tracking-wider text-center">Share</h2>
        <h2 className="mb-10 text-base-secondary text-center">
          <FormattedMessage id="quiz.poop_personality.sharing_text.results" />
        </h2>
        <SharingAsset poopPersonalityType={poopPersonalityType} poopPersonalityName={poopPersonalityName} />
        <div className="mt-8">
          <SharingLinks poopPersonalityType={poopPersonalityType} poopPersonalityName={poopPersonalityName} />
        </div>
      </div>
    </>
  )
}
