import React from 'react'
import { appConfig } from '../../../../appConfig'
import { PoopPersonalityName, PoopPersonalityType } from '../../../../redux/quiz/types'
import { CopyTextIcon } from './CopyTextIcon'
import { DownloadImageIcon } from './DownloadImageIcon'
import TagManager from 'react-gtm-module'
import { socialSharingDataLayer } from '../../gtm'

export interface SharingLinksProps {
  poopPersonalityType: PoopPersonalityType
  poopPersonalityName: PoopPersonalityName
}

export const sharingText = (
  poopPersonalityName: PoopPersonalityName,
  poopPersonalityType: PoopPersonalityType,
): string => {
  return (
    "Turns out I've got a " +
    poopPersonalityName +
    ". What's your poop personality? Join the %23bluepoopchallenge to find out: https://joinzoe.com/bluepoop/" +
    poopPersonalityType
  )
}

// Hashtag needs to be entered separately for Twitter
export const sharingTextTwitter = (poopPersonalityName: PoopPersonalityName): string => {
  return "Turns out I've got a " + poopPersonalityName + ". What's your poop personality? Find out at "
}

export const SharingLinks: React.FC<SharingLinksProps> = ({ poopPersonalityType, poopPersonalityName }) => {
  const facebookLink = () => {
    return 'https://www.facebook.com/sharer/sharer.php?u=https://joinzoe.com/bluepoop/' + poopPersonalityType
  }

  const twitterLink = () => {
    return (
      'https://twitter.com/intent/tweet?url=https://joinzoe.com/bluepoop/' +
      poopPersonalityType +
      '&text=' +
      sharingTextTwitter(poopPersonalityName) +
      '&hashtags=bluepoopchallenge'
    )
  }

  const whatsappLink = () => {
    return 'https://api.whatsapp.com/send?text=' + sharingText(poopPersonalityName, poopPersonalityType)
  }

  const emailLink = () => {
    return 'mailto:?subject=Join the #bluepoopchallenge&body=' + sharingText(poopPersonalityName, poopPersonalityType)
  }

  const facebook = {
    iconName: 'facebook',
    name: 'Facebook',
    link: facebookLink(),
  }
  const twitter = {
    iconName: 'twitter',
    name: 'Twitter',
    link: twitterLink(),
  }
  const whatsapp = { iconName: 'whatsapp', name: 'Whatsapp', link: whatsappLink() }

  const userAgent = navigator.userAgent || navigator.vendor
  const isMobile = () => {
    return userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|BlackBerry|IEMobile|Opera Mini/i)
  }

  const email = { iconName: 'email', name: 'Email', link: emailLink() }

  const sharingPlatformsToDisplay = []
  if (isMobile()) {
    sharingPlatformsToDisplay.push(facebook, twitter, whatsapp)
  } else {
    sharingPlatformsToDisplay.push(facebook, twitter, email)
  }

  const sendToGTM = (socialPlatform: string) => {
    TagManager.dataLayer({
      dataLayer: socialSharingDataLayer(socialPlatform),
    })
  }

  const instagramClickHandler = () => {
    sendToGTM('Instagram')
    window.open(`${appConfig.publicUrl}/instagram-1?poopName=${encodeURIComponent(poopPersonalityName)}`)
  }

  return (
    <>
      <div className="mx-auto flex justify-between max-w-social-icons-row-mobile sm:max-w-social-icons-row-desktop">
        {isMobile() && (
          <div onClick={instagramClickHandler}>
            <img
              src={`${appConfig.publicUrl}/img/social/instagram.png`}
              alt="Instagram icon"
              className="mx-auto max-w-social-icon"
            />
          </div>
        )}
        {sharingPlatformsToDisplay.map(({ name, iconName, link }) => (
          <a key={name} href={link} target="_blank" rel="noreferrer" onClick={() => sendToGTM(name)}>
            <img
              src={`${appConfig.publicUrl}/img/social/${iconName}.png`}
              alt={name + ' icon'}
              className="mx-auto max-w-social-icon"
            />
          </a>
        ))}
        {!isMobile() && (
          <>
            <CopyTextIcon poopPersonalityType={poopPersonalityType} />
            <DownloadImageIcon poopPersonalityType={poopPersonalityType} />
          </>
        )}
      </div>
      {isMobile() && <CopyTextIcon poopPersonalityType={poopPersonalityType} mobileVersion={true} />}
    </>
  )
}
