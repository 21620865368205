import globalAxios from 'axios'

export interface LocationIdentifier {
  countryCode: string
  error: boolean
}

export const useLocation = async (): Promise<LocationIdentifier> => {
  try {
    const { data } = await globalAxios.get('https://getipcountry.zoe.workers.dev/', {
      timeout: 500,
    })
    return { countryCode: data, error: false }
  } catch (error) {
    console.error('Could not fetch location data: ', error.response)
    return { countryCode: '', error: true }
  }
}
