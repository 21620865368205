import React from 'react'
import { FormattedMessage } from 'react-intl'

export const PufaAndInflammation: React.FC = () => (
  <div className="text-footnote light">
    <p className="text-footnote light">
      <FormattedMessage id="quiz.gut_twin.microbes.higher_pufa" />
    </p>

    <p className="text-footnote light pt-4">
      <FormattedMessage id="quiz.gut_twin.microbes.lower_inflammation" />
    </p>
  </div>
)
