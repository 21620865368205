import React from 'react'
import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { ScreenProps } from '../types'
import { Button } from 'components/core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
import { TextLinkButton } from '../../core/buttons/TextLinkButton'
import { appConfig } from '../../../appConfig'

export type FormValues = { [key: string]: string }
export type FormValidationSchema = any | (() => any)

export interface FormProps {
  body?: React.ReactNode
  initialValues?: FormValues
  validationSchema?: FormValidationSchema
}

export interface SectionEndScreenProps extends ScreenProps {
  headerBar?: React.ReactNode
  headerImage?: React.ReactNode
  sectionTitle?: React.ReactNode
  headline?: React.ReactNode
  screenTitle?: React.ReactNode
  footnote?: React.ReactNode
  primaryButtonText?: React.ReactNode
  secondaryButtonText?: React.ReactNode
  isForm?: boolean
  poopCharacter?: string
  poopText?: React.ReactNode
  poopTextBackgroundColour?: string

  form?: FormProps
}

export const SectionEndScreen: React.FC<SectionEndScreenProps> = ({
  headerBar,
  headerImage,
  sectionTitle,
  headline,
  screenTitle,
  footnote,
  primaryButtonText,
  secondaryButtonText,
  isForm,
  poopCharacter,
  poopText,
  poopTextBackgroundColour,
  form,
  onNextClicked,
}) => {
  const initialValues = form?.initialValues || {}

  const setValueAndSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values: FormValues,
    setValues: any,
    submitForm: () => void,
    newValue: string,
  ) => {
    e.preventDefault()
    const question = Object.keys(values)[0]
    setValues({ ...values, [question]: newValue })
    submitForm()
  }

  return (
    <div className="h-full bg-background">
      <LogoHeader />
      {headerBar}
      <main className="flex flex-row flex-1 w-full justify-center bg-background">
        <div
          className="flex flex-col max-w-info-width-mobile sm:max-w-info-width-desktop justify-around mt-10 sm:mt-20"
          style={{ boxSizing: 'initial' }}
        >
          <div className="flex flex-col items-center">
            {headerImage && (
              <img src={`${appConfig.publicUrl}/img/icons/${headerImage}`} className="block mx-auto mb-10" />
            )}
            {sectionTitle && <p className="text-base-primary light sm:text-lg mb-4 sm:mb-6 ">{sectionTitle}</p>}
            {headline && <h1 className="text-center light -mt-6 mb-6">{headline}</h1>}
            {screenTitle && <h3 className="text-center light">{screenTitle}</h3>}
            {poopCharacter && (
              <div
                className={`mt-16 mb-8 bg-${poopTextBackgroundColour} rounded-2xl pt-3 pb-4 px-4 w-small-speech-bubble relative mx-auto`}
              >
                <p className="light text-center">{poopText}</p>
                <div
                  className={`w-0 h-0 border-t-14 border-${poopTextBackgroundColour} left-speech-arrow-left absolute`}
                  style={{
                    borderLeft: '14px solid transparent',
                    bottom: -14,
                  }}
                ></div>
                <img
                  src={`${appConfig.publicUrl}/img/poop/${poopCharacter}.png`}
                  alt={poopCharacter}
                  className="w-poop-personality-character-side absolute"
                  style={{ top: 60, right: 10 }}
                />
              </div>
            )}
          </div>

          {!isForm && (
            <div className="mt-40 mb-12 text-center">
              {footnote && (
                <p className="text-center text-base-primary text-footnote light sm:text-lg mb-8 sm:mb-16 ">
                  {footnote}
                </p>
              )}
              <Button onClick={onNextClicked}>
                <FormattedMessage id="next" />
              </Button>
            </div>
          )}

          {isForm && form && (
            <Formik
              initialValues={initialValues}
              validationSchema={form.validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                onNextClicked(values)
                setSubmitting(false)
              }}
            >
              {({ isSubmitting, submitForm, setValues, values }) => (
                <Form className="mt-6 sm:mt-8">
                  {form.body}
                  <div className="mt-20 mb-12 text-center flex flex-col items-center">
                    <Button
                      onClick={(e) => setValueAndSubmit(e, values, setValues, submitForm, 'true')}
                      disabled={isSubmitting}
                      long="true"
                    >
                      {primaryButtonText || <FormattedMessage id="next" />}
                    </Button>
                    <TextLinkButton
                      onClick={(e) => setValueAndSubmit(e, values, setValues, submitForm, 'false')}
                      disabled={isSubmitting}
                      className="mt-6 text-base-primary"
                    >
                      {secondaryButtonText || <FormattedMessage id="next" />}
                    </TextLinkButton>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </main>
    </div>
  )
}
