import React, { useEffect, useMemo, useState } from 'react'
import { clearQuiz, getResults, updateQuizWithId } from '../../../redux/quiz/slice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../App'
import { GoodMicrobesScientificName, PoopPersonalityName, QuizVersion } from '../../../redux/quiz/types'
import { parseResultsPageQueryParams } from '../webflow'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { TransitTimeResult } from '../vBluePoo/components/TransitTimeResult'
import { PoopPersonalityResult } from '../vBluePoo/components/PoopPersonalityResult'
import { appConfig } from '../../../appConfig'
import { POOP_PERSONALITIES } from '../vBluePoo/constants'
import { GutTwinResult } from '../vBluePoo/components/GutTwinResult'
import { SharingModule } from '../vBluePoo/components/SharingModule'
import { SummaryResult } from '../vBluePoo/components/SummaryResult'
import { GutMicrobesResult } from '../vBluePoo/components/GutMicrobesResult'

type bodyVariation = 'transitTime' | 'poopPersonality' | 'gutTwin' | 'gutMicrobes' | 'summary'

export interface ResultsScreenProps {
  version: QuizVersion
  backgroundColour?: string
  secondaryBackgroundColour?: string
  bodyVariation: bodyVariation
  nextPageIntro?: React.ReactNode
  footer?: React.ReactNode
}

interface ResultsType {
  transit_time_hrs: number
  // transit_time_position: number
  poop_personality: PoopPersonalityName
  name: string
  age: number
  twin_age: number
  twin_name: string
  twin_sex: 'male' | 'female'
  twin_height_ft: number
  twin_height_in: number
  twin_weight: number
  twin_good_bugs_number: number
  twin_good_bugs_names: GoodMicrobesScientificName[]
}

export const ResultsScreen: React.FC<ResultsScreenProps & RouteComponentProps> = ({
  version,
  backgroundColour,
  secondaryBackgroundColour,
  bodyVariation,
  nextPageIntro,
  footer,
  location,
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const { id } = useMemo(() => parseResultsPageQueryParams(location), [location])

  const dispatch = useDispatch<AppDispatch>()
  const [results, setResults] = useState<ResultsType>({
    transit_time_hrs: 0,
    poop_personality: 'Storm Pooper', // this gives default background colour
    name: '',
    age: 0,
    twin_age: 0,
    twin_name: '',
    twin_sex: 'male',
    twin_height_ft: 0,
    twin_height_in: 0,
    twin_weight: 0,
    twin_good_bugs_number: 0,
    twin_good_bugs_names: ['Bifidobacterium animalis'],
  })

  const fetchResults = async () => {
    try {
      const user_id = id ? id : ''
      const { payload } = await dispatch(getResults({ quiz_version: version, user_id: user_id }))
      const data = await payload[version]
      if (Object.keys(data).length === 0) {
        setLoading(false)
        history.push('results-error')
      } else {
        setResults(data)
        setLoading(false)
        if (id) {
          // need to update state for this new user_id rather than relying on browser cache state
          await dispatch(
            updateQuizWithId({
              quiz: data,
              version: version,
              id,
            }),
          )
        }
      }
    } catch (error) {
      console.error('Could not load Blue Poop results: ', error)
      setLoading(false)
      history.push('results-error')
    }
  }

  useEffect(() => {
    fetchResults()
  }, [])

  const poopPersonalityType = POOP_PERSONALITIES[results.poop_personality]?.poopPersonalityType
  const renderBody = () => {
    switch (bodyVariation) {
      case 'poopPersonality':
        return <PoopPersonalityResult poopPersonalityName={results.poop_personality} />
      case 'transitTime':
        return <TransitTimeResult transitTimeHrs={results.transit_time_hrs} />
      case 'gutTwin':
        return (
          <GutTwinResult
            name={results.name}
            age={results.age}
            twinName={results.twin_name}
            twinGender={results.twin_sex}
            twinHeightFt={results.twin_height_ft}
            twinHeightIn={results.twin_height_in}
            twinAge={results.twin_age}
            twinWeightLbs={results.twin_weight}
          />
        )
      case 'gutMicrobes':
        return (
          <GutMicrobesResult
            backgroundColour="results-background-deep-blue"
            twinName={results.twin_name}
            twinGender={results.twin_sex}
            twinHeightFt={results.twin_height_ft}
            twinHeightIn={results.twin_height_in}
            twinAge={results.twin_age}
            twinWeightLbs={results.twin_weight}
            twinGoodBugsNumber={results.twin_good_bugs_number}
            twinGoodBugsNames={results.twin_good_bugs_names ?? []}
          />
        )
      case 'summary':
        return (
          <SummaryResult
            poopPersonalityName={results.poop_personality}
            transitTimeHrs={results.transit_time_hrs}
            name={results.name}
            age={results.age}
            twinName={results.twin_name}
            twinGender={results.twin_sex}
            twinAge={results.twin_age}
            twinGoodBugsNumber={results.twin_good_bugs_number}
          />
        )
    }
  }

  const redoQuiz = async () => {
    // need to await to be entirely sure localForage is cleared
    await dispatch(clearQuiz())
    window.location.assign(`${appConfig.publicUrl}/`)
  }

  const screenData = (bodyVariation: bodyVariation) => {
    const screens = ['poopPersonality', 'transitTime', 'gutTwin', 'gutMicrobes', 'summary']
    const screenNumber = screens.indexOf(bodyVariation) + 1
    const total = screens.length
    return `Part ${screenNumber} of ${total}`
  }

  return (
    <>
      {!loading && (
        <>
          <div className={`pt-16 bg-${backgroundColour ? backgroundColour : poopPersonalityType + '-background'}`}>
            <div
              className={`${
                bodyVariation === 'summary' ? 'bg-background' : 'bg-black opacity-20'
              }  py-8 sm:py-10 w-full absolute top-0`}
            ></div>
            <div className="flex absolute top-0 left-0 right-0  mx-auto max-w-info-width-mobile sm:max-w-results-width-desktop">
              <p className="text-fineprint font-extrabold my-6 sm:my-8 text-left text-base-primary w-1/3 sm:text-base sm:leading-6">
                {screenData(bodyVariation)}
              </p>
              <p className="text-fineprint light my-6 sm:my-8 text-right text-base-primary w-2/3 sm:text-base sm:leading-6 sm:font-light">
                Want to redo the quiz?{' '}
                <span onClick={redoQuiz} className="cursor-pointer underline">
                  Click here
                </span>
                .
              </p>
            </div>

            <header className="pt-8 sm:pt-16">
              {backgroundColour === 'white' ? (
                <img src={`${appConfig.publicUrl}/img/logos/zoe-logo-red.svg`} alt="ZOE logo" className="mx-auto" />
              ) : (
                <img src={`${appConfig.publicUrl}/img/logos/zoe-logo.svg`} alt="ZOE logo" className="mx-auto" />
              )}
            </header>
            <main className="flex justify-center">{renderBody()}</main>
            {nextPageIntro && <div>{nextPageIntro}</div>}
            {bodyVariation !== 'summary' && bodyVariation !== 'gutMicrobes' && (
              <div
                className={`bg-${
                  secondaryBackgroundColour ? secondaryBackgroundColour : poopPersonalityType + '-background-secondary'
                }`}
              >
                <SharingModule poopPersonalityName={results.poop_personality} />
              </div>
            )}
            {footer && <footer>{footer}</footer>}
          </div>
        </>
      )}
    </>
  )
}
