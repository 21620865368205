import { FormattedMessage } from 'react-intl'
import React from 'react'
import { appConfig } from '../../../../appConfig'
import { transitTimeComparator } from '../../../../data/transitTimeCompator'

interface TransitTimeBarComparisonCardProps {
  transitTimeHrs: number
}

export const TransitTimeBarComparisonCard: React.FC<TransitTimeBarComparisonCardProps> = ({ transitTimeHrs }) => {
  const transitTimeDisplay = () => {
    const minutes = Math.round((transitTimeHrs - Math.floor(transitTimeHrs)) * 60)
    const minutesDisplay = minutes < 10 ? '0' + minutes : minutes
    return Math.floor(transitTimeHrs) + ':' + minutesDisplay
  }

  const medianResultPosition = () => {
    switch (transitTimeComparator(transitTimeHrs).bars) {
      case 'faster':
        return 'right-0 sm:right-100px'
      case 'aboutthesame-faster':
        return 'right-0 sm:right-100px'
      case 'thesame':
        return 'right-0 sm:right-100px'
      case 'aboutthesame-slower':
        return 'right-10px sm:right-110px'
      default:
        return 'right-90px sm:right-200px'
    }
  }

  const usersResultPosition = () => {
    switch (transitTimeComparator(transitTimeHrs).bars) {
      case 'faster':
        return 'right-90px sm:right-200px'
      case 'aboutthesame-faster':
        return 'right-10px sm:right-110px'
      case 'thesame':
        return 'right-0 sm:right-100px'
      case 'aboutthesame-slower':
        return 'right-0 sm:right-100px'
      default:
        return 'right-0 sm:right-100px'
    }
  }

  return (
    <div className="text-center sm:max-w-transit-graph-card mx-auto">
      <h2 className="uppercase tracking-wider mb-3">
        <FormattedMessage id="quiz.transit_time_results.bar_comparison.title" />
      </h2>
      <p className="light mb-12 mx-auto sm:max-w-transit-graph-card">
        <FormattedMessage
          id="quiz.transit_time_results.bar_comparison.statement"
          values={{
            comparator: transitTimeComparator(transitTimeHrs).comparedToMedian,
          }}
        />
      </p>
      <div className="relative">
        <img
          src={`${appConfig.publicUrl}/img/transit-graphs/transit-bars-${
            transitTimeComparator(transitTimeHrs).bars
          }.png`}
          className="w-transit-graph-width mx-auto"
        />
        <span
          className={`absolute text-fineprint top-15px sm:right-transit-bar-chart-time
        ${medianResultPosition()}`}
        >
          28:42
        </span>
        <span className={`absolute text-fineprint bottom-0  ${usersResultPosition()}`}>{transitTimeDisplay()}</span>
      </div>
    </div>
  )
}
