type transitTimeComparatorValues = {
  speed: 'fast' | 'slow' | 'normal'
  chart: 'fast' | 'slow' | 'normal-slow' | 'normal-middle' | 'normal-fast'
  comparedToMedian: 'faster than' | 'slower than' | 'about the same compared to' | 'the same compared to'
  bars: 'faster' | 'slower' | 'aboutthesame-faster' | 'aboutthesame-slower' | 'thesame'
}

export const transitTimeComparator = (transitTimeHrs: number): transitTimeComparatorValues => {
  if (transitTimeHrs < 14) {
    return { speed: 'fast', chart: 'fast', comparedToMedian: 'faster than', bars: 'faster' }
  } else if (transitTimeHrs < 27.5) {
    // Note: +/- 1.5 hours from mean of 28:42 classed as "about the same"
    return { speed: 'normal', chart: 'normal-fast', comparedToMedian: 'faster than', bars: 'faster' }
  } else if (transitTimeHrs < 28.7) {
    // Note: +/- 1.5 hours from mean of 28:42 classed as "about the same"
    return {
      speed: 'normal',
      chart: 'normal-fast',
      comparedToMedian: 'about the same compared to',
      bars: 'aboutthesame-faster',
    }
  } else if (transitTimeHrs === 28.7) {
    return { speed: 'normal', chart: 'normal-fast', comparedToMedian: 'the same compared to', bars: 'thesame' }
  } else if (transitTimeHrs < 29) {
    // Note: based on backend calculations, this is cutoff for normal
    return {
      speed: 'normal',
      chart: 'normal-fast',
      comparedToMedian: 'about the same compared to',
      bars: 'aboutthesame-faster',
    }
  } else if (transitTimeHrs < 30.5) {
    // Note: +/- 1.5 hours from mean of 28:42 classed as "about the same"
    return {
      speed: 'normal',
      chart: 'normal-middle',
      comparedToMedian: 'about the same compared to',
      bars: 'aboutthesame-slower',
    }
  } else if (transitTimeHrs < 44) {
    return { speed: 'normal', chart: 'normal-middle', comparedToMedian: 'slower than', bars: 'slower' }
  } else if (transitTimeHrs < 59) {
    return { speed: 'normal', chart: 'normal-slow', comparedToMedian: 'slower than', bars: 'slower' }
  } else {
    return { speed: 'slow', chart: 'slow', comparedToMedian: 'slower than', bars: 'slower' }
  }
}
