import React from 'react'
import { useIntl } from 'react-intl'

import { SelectField } from './SelectField'
import { LocationOption } from '../../../redux/quiz/types'

export interface LocationSelectFieldProps extends React.ComponentProps<'select'> {
  options: LocationOption[]
  name: string
  selected?: (arg0: string) => void
}

export const LocationSelectField: React.FC<LocationSelectFieldProps> = ({ selected, ...props }) => {
  const intl = useIntl()

  return (
    <SelectField
      name={props.name}
      options={props.options}
      label={intl.formatMessage({ id: `quiz.location.${props.name}.label` })}
      selected={selected}
    />
  )
}
