import React, { Dispatch, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { StringSchema } from 'yup'

import { FFQuestion } from '../../../core/fields/FFQuestion'
import { FormValidationSchema } from '../../screens/FormScreen'
import { camelToSnakeCase } from '../../utils'

const QUESTIONS = [
  // statusName should match backend diet score model
  { label: 'Fruit', subLabel: '(canned or fresh)', statusName: 'fruit' },
  { label: 'Fruit juice', subLabel: '(not from concentrate)', statusName: 'fruitJuice' },
  { label: 'Salad', subLabel: '(not garnish added to sandwiches)', statusName: 'salad' },
  { label: 'Vegetables', subLabel: '(including canned, frozen, fresh, not potatoes)', statusName: 'vegetables' },
  { label: 'French fries / potatoes', statusName: 'chips' },
  { label: 'Beans or legumes', subLabel: '(including black beans, chickpeas, lentils)', statusName: 'pulses' },
  {
    label: 'Fiber-rich breakfast cereal',
    subLabel: '(like All-Bran, Fiber One, Oatmeal, Muesli)',
    statusName: 'fibre_rich_breakfast',
  },
  { label: 'Whole grain bread, pita or flatbread', statusName: 'wholemealBread' },
  { label: 'Cheese / yogurt', statusName: 'cheeseYogurt' },
  { label: 'Chips / savory snacks', statusName: 'crispsSnacks' },
  { label: 'Cookies, cakes, chocolate, candy, desserts', statusName: 'sweets' },
  { label: 'Ice cream / cream', statusName: 'iceCream' },
  { label: 'Non-alcoholic carbonated drinks / soda', subLabel: '(not sugar-free or diet)', statusName: 'fizzyPop' },
  {
    label: 'Beef, lamb, pork, ham - steaks, roasts, chops, shoulders, or ground meat',
    subLabel: '(not served as a burger)',
    statusName: 'redMeat',
  },
  {
    label: 'Chicken or turkey',
    subLabel: '(not in batter or breadcrumbs)',
    statusName: 'whiteMeat',
  },
  {
    label: 'Sausages, bacon, corned beef, or burgers',
    statusName: 'redProcessedMeat',
  },
  {
    label: 'Chicken / turkey nuggets, turkey burgers, chicken pot pies, or in batter or breaded',
    statusName: 'whiteProcessedMeat',
  },
  {
    label: 'White fish in batter or breaded',
    subLabel: '(like ‘fish ‘n chips’)',
    statusName: 'whiteFishBatteredBreaded',
  },
  {
    label: 'White fish not in batter or breadcrumbs',
    subLabel: '(like cod, tilapia, halibut, bass, haddock)',
    statusName: 'whiteFish',
  },
  {
    label: 'Oily fish',
    subLabel: '(like herring, sardines, salmon, trout, mackerel, fresh tuna, but not canned tuna)',
    statusName: 'oilyFish',
  },
]

const questionOrder: string[] = QUESTIONS.map((question) => {
  return question.statusName
})

export const FFQForm: React.FC = () => {
  // Valid statuses: 'incomplete' -> show options, '' -> hide options, or one of the 8 FFQ answers
  const INVALID_STATUSES = ['', 'incomplete']

  // set up initial question so that it displays the answer options
  const [foodGroupState, setFoodGroupState] = useState('incomplete')
  const foodGroupStates: { [key: string]: string } = { [questionOrder[0]]: foodGroupState }
  const setFoodGroupStates: { [key: string]: Dispatch<string> } = { fruit: setFoodGroupState }

  // set up remaining questions' states to hide answers
  for (let i = 1; i < questionOrder.length; i++) {
    const [foodGroupState, setFoodGroupState] = useState('')
    foodGroupStates[questionOrder[i]] = foodGroupState
    setFoodGroupStates[questionOrder[i]] = setFoodGroupState
  }

  useEffect(
    () => {
      // run through questions and check for next question in line to update to 'incomplete' state
      for (let i = 0; i < questionOrder.length - 1; i++) {
        const currentQuestion = questionOrder[i]
        const nextQuestion = questionOrder[i + 1]

        !INVALID_STATUSES.includes(foodGroupStates[currentQuestion]) &&
        INVALID_STATUSES.includes(foodGroupStates[nextQuestion])
          ? setFoodGroupStates[nextQuestion]('incomplete')
          : null
      }
    },
    questionOrder.map((question) => {
      return foodGroupStates[question]
    }),
  )

  return (
    <>
      {QUESTIONS.map((question) => {
        return (
          <FFQuestion
            key={question.statusName}
            label={question.label}
            subLabel={question.subLabel || undefined}
            foodGroup={camelToSnakeCase(question.statusName)}
            icon={camelToSnakeCase(question.statusName)}
            status={foodGroupStates[question.statusName]}
            updateStatus={setFoodGroupStates[question.statusName]}
          />
        )
      })}
    </>
  )
}

export const validationSchema = (): FormValidationSchema => {
  const validationObject: { [key: string]: StringSchema } = {}

  // validation schema keys based on name prop of radio button in FFQuestion
  questionOrder.forEach((question) => {
    validationObject['ffq_' + camelToSnakeCase(question)] = Yup.string().required()
  })

  return Yup.object(validationObject)
}
