import { QuizVersion } from 'redux/quiz/types'

export type GTMDataLayer = { [key: string]: string | number }

export const quizDataLayer = (question: string, answer: string | number, version: QuizVersion): GTMDataLayer => ({
  event: 'quiz_question',
  question,
  answer,
  version: version.replace('v', ''), // match WebFlow
})

export const socialSharingDataLayer = (socialPlatform: string): GTMDataLayer => ({
  event: 'bluepoop_personality_share_step_1',
  bluepoop_sharing_method: socialPlatform,
})

export const instagramDeepLinkLayer = (): GTMDataLayer => ({
  event: 'bluepoop_personality_share_step_2',
  bluepoop_sharing_method: 'Instagram',
})

export const summaryCtaDataLayer = (): GTMDataLayer => ({
  event: 'CTA',
  cta_position: 'blue poop summary',
  cta: 'Learn more about ZOE',
})
