import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Polaroid } from './Polaroid'

export interface GutTwinResultCardProps {
  name: string
  age: number
  twinName: string
  twinGender: 'male' | 'female'
  twinAge: number
}

export const GutTwinResultCard: React.FC<GutTwinResultCardProps> = ({ name, age, twinName, twinGender, twinAge }) => {
  // These vars are needed when we have more pics.
  // Code to stop linting breaking
  if (age && twinAge) {
    console.log('')
  }
  return (
    <div className={`w-full h-full bg-results-background-teal flex justify-center items-center`}>
      <div>
        <div>
          {/* Note: twinAge is hardcoded to 20 for v1 as we have just 2 images  */}
          <div className="relative mt-2" style={{ margin: 'auto', height: '120px', width: '120px' }}>
            <div className="absolute" style={{ top: '30px', left: '40px' }}>
              <Polaroid
                twinGender={twinGender}
                twinName={name}
                twinAge={age}
                rotation={'rotate-6'}
                size={'small'}
                image={'bg.png'}
              />
            </div>
            <div className="absolute" style={{ left: '0' }}>
              <Polaroid
                twinGender={twinGender}
                twinName={twinName}
                twinAge={twinAge}
                rotation={'-rotate-6'}
                size={'small'}
              />
            </div>
          </div>
        </div>
        <p className="uppercase text-fineprint-spread sm:text-footnote-bold tracking-wider mt-3 sm:mt-5 px-4">
          <FormattedMessage id="quiz.summary.gut_twin.statement" />
        </p>
      </div>
    </div>
  )
}
