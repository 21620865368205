import React, { useEffect, useState, SetStateAction } from 'react'

import { LocationSelectField } from '../../../core/fields/LocationSelectField'
import { COUNTRIES, US_STATES } from '../constants'
import { useLocation } from '../useLocation'
import { IntlShape } from 'react-intl'
import { FormValidationSchema } from '../../screens/FormScreen'
import * as Yup from 'yup'

export const LocationForm: React.FC = () => {
  const [countryCode, setCountryCode] = useState('')
  const [error, setError] = useState(false)

  const [selectedCountry, setSelectedCountry] = useState('')

  const checkIfUS = (value: SetStateAction<string>): void => {
    setSelectedCountry(value)
  }

  const showStateDropdown = (): boolean => {
    return (countryCode === 'US' && !error && (selectedCountry === 'US' || selectedCountry === '')) ||
      selectedCountry === 'US'
      ? true
      : false
  }

  useEffect(() => {
    ;(async () => {
      const { countryCode, error } = await useLocation()
      setCountryCode(countryCode)
      setError(error)
    })()
  }, [])

  return (
    <>
      <LocationSelectField name="country" options={COUNTRIES} selected={checkIfUS} />
      {showStateDropdown() && (
        <div className="mt-4">
          <LocationSelectField name="state" options={US_STATES} />
        </div>
      )}
    </>
  )
}

export const validationSchema = (intl: IntlShape): FormValidationSchema => {
  return Yup.object({
    country: Yup.mixed()
      .required(intl.formatMessage({ id: 'quiz.location.country_field.error' }))
      .notOneOf(['']),
    state: Yup.mixed()
      .when('country', {
        is: 'US',
        then: Yup.string().required(intl.formatMessage({ id: 'quiz.location.state_field.error' })),
      })
      .notOneOf(['']),
  })
}
