import React from 'react'
import { useFormikContext } from 'formik'
import { FieldError } from './FieldError'

export interface RadioButtonGroupProps extends React.ComponentProps<'input'> {
  children: React.ReactNode
  name: string
  errorClassName?: string
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ ...props }) => {
  const context = useFormikContext()
  const errors = { ...(context.errors as any) }

  return (
    <fieldset id={props.name} className={`grid gap-y-4 grid-cols-1 ${props.className}`}>
      {props.children}
      {errors[props.name] && <FieldError className={props.errorClassName}>{Object.values(context.errors)}</FieldError>}
    </fieldset>
  )
}
