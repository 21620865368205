import React from 'react'

import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import App from './App'
import { appConfig, __DEV__ } from 'appConfig'

import messages from 'i18n/locales/en.json'
import compiledMessages from 'i18n/compiled/en.json'

import 'styles/index.css'

Sentry.init({
  dsn: appConfig.sentryDsn,
  environment: appConfig.environment,
  normalizeDepth: 5,
})

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <React.StrictMode>
      <App locale="en" messages={__DEV__ ? messages : compiledMessages} />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from "./reportWebVitals";
// reportWebVitals();
