import { Answer, QuestionKey } from 'redux/quiz/slice'
import { FormValues } from './screens/FormScreen'

export const formValuesToAnswers = (values: FormValues): Answer[] =>
  Object.entries(values).map(([question_key, answer]) => ({
    question_key: question_key as QuestionKey,
    answer,
  }))

// eslint-disable-next-line
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
