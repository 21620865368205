import React from 'react'

export type TextLinkButtonProps = React.ComponentProps<'button'>

export const TextLinkButton: React.FC<TextLinkButtonProps> = React.memo((props) => (
  <button
    {...props}
    className={`box-border appearance-none
      w-full light
      cursor-pointer focus:outline-none underline ${props.className}`}
  />
))
