import React from 'react'
import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'

import { appConfig } from '../../../appConfig'
import { ScreenProps } from '../types'
import { Button } from 'components/core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
import { TextLinkButton } from '../../core/buttons/TextLinkButton'

export type FormValues = { [key: string]: string }
export type FormValidationSchema = any | (() => any)

export interface FormProps {
  body?: React.ReactNode
  initialValues?: FormValues
  validationSchema?: FormValidationSchema
}

export interface FormMultiSubmitButtonsScreenProps extends ScreenProps {
  headerBar: React.ReactNode
  sectionTitle?: React.ReactNode
  screenTitle: React.ReactNode
  description?: React.ReactNode
  image?: React.ReactNode
  primaryButtonText?: React.ReactNode
  secondaryButtonText?: React.ReactNode

  form: FormProps
}

export const FormMultiSubmitButtonsScreen: React.FC<FormMultiSubmitButtonsScreenProps> = ({
  headerBar,
  sectionTitle,
  screenTitle,
  description,
  image,
  primaryButtonText,
  secondaryButtonText,
  form,
  onNextClicked,
}) => {
  const { body, initialValues = {}, validationSchema } = form

  const setValueAndSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values: FormValues,
    setValues: any,
    submitForm: () => void,
    newValue: string,
  ) => {
    e.preventDefault()
    const question = Object.keys(values)[0]
    setValues({ ...values, [question]: newValue })
    submitForm()
  }

  return (
    <div className="h-full bg-background">
      <LogoHeader />
      {headerBar}
      <main className="flex flex-row flex-1 w-full justify-center bg-background">
        <div
          className="flex flex-col max-w-info-width-mobile sm:max-w-info-width-desktop justify-between mt-10 sm:mt-20 sm:justify-start"
          style={{ boxSizing: 'initial' }}
        >
          <div className="flex mb-4 sm:mb-6 items-center justify-center">
            {sectionTitle && <p className="text-base-primary light sm:text-lg">{sectionTitle}</p>}
          </div>
          <h3 className="text-center sm:leading-8">{screenTitle}</h3>
          {description && <div className="text-center mt-6">{description}</div>}

          {form && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                onNextClicked(values)
                setSubmitting(false)
              }}
            >
              {({ isSubmitting, submitForm, setValues, values }) => (
                <Form className="mt-6 sm:mt-8">
                  {body}
                  {image && <img src={`${appConfig.publicUrl}${image}`} className="block mx-auto mt-8" />}
                  <div className="mt-20 mb-12 text-center flex flex-col items-center">
                    <Button
                      onClick={(e) => setValueAndSubmit(e, values, setValues, submitForm, 'true')}
                      disabled={isSubmitting}
                      long="true"
                    >
                      {primaryButtonText || <FormattedMessage id="next" />}
                    </Button>
                    <TextLinkButton
                      onClick={(e) => setValueAndSubmit(e, values, setValues, submitForm, 'false')}
                      disabled={isSubmitting}
                      className="mt-6 text-base-primary"
                    >
                      {secondaryButtonText || <FormattedMessage id="next" />}
                    </TextLinkButton>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </main>
    </div>
  )
}
