import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TransitTimeGraphCard } from './TransitTimeGraphCard'
import { appConfig } from '../../../../appConfig'
import { TransitTimeHoursMins } from './TransitTimeHoursMins'
import { TransitTimeBarComparisonCard } from './TransitTimeBarComparisonCard'
import { BluePoopChallengeHashtag } from './BluePoopChallengeHashtag'

export interface TransitTimeResultProps {
  transitTimeHrs: number
}

export const TransitTimeResult: React.FC<TransitTimeResultProps> = ({ transitTimeHrs }) => {
  return (
    <div className="w-full">
      <div className="bg-background pt-16 pb-20 sm:pb-16">
        <img src={`${appConfig.publicUrl}/img/transit-time-gut.png`} className="mx-auto w-transit-time-gut" />
        <div className="mx-auto mb-12 max-w-info-width-mobile sm:max-w-results-width-desktop text-center">
          <TransitTimeHoursMins transitTimeHours={transitTimeHrs} />
        </div>
        <BluePoopChallengeHashtag />
      </div>
      <div className="bg-results-background-dark-blue relative pt-8 sm:pt-24 sm:pb-20">
        <div
          className={`w-0 h-0 border-b-14 border-results-background-dark-blue right-section-arrow absolute`}
          style={{
            borderLeft: '14px solid transparent',
            borderRight: '14px solid transparent',
            top: -14,
          }}
        ></div>
        <div className="max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto px-2 py-8 sm:bg-background sm:rounded-2xl sm:pt-12 sm:pb-16 relative">
          <div
            className="hidden sm:block w-0 h-0 border-b-14 border-background left-large-speech-arrow absolute"
            style={{
              borderRight: '14px solid transparent',
              top: -14,
            }}
          ></div>
          <img
            src={`${appConfig.publicUrl}/img/poop/pooptastrophe.png`}
            alt="Pooptastrophe image"
            className="w-poop-personality-character-front absolute hidden sm:block"
            style={{
              top: -80,
              left: 45,
            }}
          />
          <TransitTimeBarComparisonCard transitTimeHrs={transitTimeHrs} />
        </div>
        <hr className="my-4 w-info-width-mobile mx-auto border-predict sm:hidden" />
        <div className="max-w-results-width-mobile sm:max-w-results-width-desktop mx-auto px-2 py-6 sm:bg-background sm:rounded-2xl sm:mt-8 sm:pt-12 sm:pb-16">
          <TransitTimeGraphCard transitTimeHrs={transitTimeHrs} />
        </div>

        <div className="mt-2 pb-20 sm:mt-20 sm:pb-12">
          <div className={`bg-background rounded-2xl pt-3 pb-4 px-8 w-small-speech-bubble relative mx-auto`}>
            <p className="light text-center">
              <FormattedMessage id="quiz.transit_time_results.interesting" />
            </p>
            <div
              className={`w-0 h-0 border-t-14 border-background right-speech-arrow-left absolute`}
              style={{
                borderLeft: '14px solid transparent',
                bottom: -14,
              }}
            ></div>
            <img
              src={`${appConfig.publicUrl}/img/poop/superpooper.png`}
              alt="Super Pooper says that's interesting"
              className="w-poop-personality-character-side transform -rotate-12 -mr-12 absolute"
              style={{ top: 10, right: -60 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
