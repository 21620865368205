import React from 'react'
import { appConfig } from '../../../../appConfig'
import { FormattedMessage } from 'react-intl'
import { transitTimeComparator } from '../../../../data/transitTimeCompator'

export interface TransitTimeResultCardProps {
  transitTimeHrs: number
}

export const TransitTimeResultCard: React.FC<TransitTimeResultCardProps> = ({ transitTimeHrs }) => {
  const hours = Math.floor(transitTimeHrs)
  const minutes = Math.floor((transitTimeHrs - hours) * 60)

  return (
    <div className={`w-full h-full bg-background flex justify-center items-center`}>
      <div>
        <img src={`${appConfig.publicUrl}/img/icons/gut.svg`} alt="Gut icon" className="mx-auto" />
        <div className="flex flex-col mt-3">
          <div>
            <span className="text-4xl">{hours}</span>
            <span className="text-base"> HRS</span>
          </div>
          <div>
            <span className={`text-4xl ${minutes < 10 ? 'inline' : 'hidden'}`}>0</span>
            <span className="text-4xl">{minutes}</span>
            <span className="text-base"> MIN</span>
          </div>
        </div>
        <p className="uppercase text-fineprint-spread sm:text-footnote-bold tracking-wider mt-3 sm:mt-5 px-4">
          <FormattedMessage
            id="quiz.summary.transit_time.statement"
            values={{
              speed: transitTimeComparator(transitTimeHrs).speed,
            }}
          />
        </p>
      </div>
    </div>
  )
}
