import { useField, useFormikContext } from 'formik'
import React from 'react'

import { FieldError } from './FieldError'
import { FieldLabel } from './FieldLabel'

export interface InputFieldProps extends React.ComponentProps<'input'> {
  label?: React.ReactNode
  name: string // needed for useField(props)
}

export const InputField: React.FC<InputFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const context = useFormikContext()

  const onChangeValidation = async (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e) // manually tell Formik to update the field value, then check overall Form validation
    await context.validateForm({
      ...(context.values as any),
      [props.name]: e.target.value, // ensures validation with the new value
    })
  }

  return (
    <div className="flex flex-col">
      {label && (
        <FieldLabel htmlFor={props.id || props.name} className="light mb-2">
          {label}
        </FieldLabel>
      )}
      <input
        className={`appearance-none bg-background-secondary h-input-field px-6 py-5 rounded-none border  
          focus:outline-none focus:border-divider-secondary placeholder-white
          ${meta.error && meta.touched ? 'border-zoe-active' : 'border-transparent'}
        `}
        {...field} // ensure onChange comes after ...field as it overwrites default properties
        {...props}
        onChange={onChangeValidation}
      />
      {(meta.touched || meta.value.toString().length > 1) && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
