import React, { useCallback, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { FormScreen, FormValues } from '../screens/FormScreen'
import { InfoOnlyScreen } from '../screens/InfoOnlyScreen'
import { ResultsScreen } from '../screens/ResultsScreen'
import { ExitScreen } from '../screens/ExitScreen'

import { Screen } from './screens'
import { Quizes, QuizVersion } from 'redux/quiz/types'
import { IntlShape, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Answer,
  saveAndCalculateResults,
  selectQuiz,
  updateAndSaveAnswers,
  updateAnswersInState,
} from 'redux/quiz/slice'
import { formValuesToAnswers } from '../utils'
import { useNavigator } from './useNavigator'
import { EntryScreen } from '../screens/EntryScreen'
import { OnBackClickedCallback, OnFormSubmitCallback, OnNextClickedCallback } from '../types'
import { GTM_QUESTION_KEY_ALLOW_LIST } from './constants'
import { quizDataLayer } from '../gtm'
import { useQuizService } from '../../../contexts/QuizServiceContext'
import { AppDispatch } from '../../../App'
import { FormMultiSubmitButtonsScreen } from '../screens/FormMultiSubmitButtonsScreen'
import { ToggleInputsFormScreen } from '../screens/ToggleInputsFormScreen'
import { SectionEndScreen } from '../screens/SectionEndScreen'
import { InstagramScreen } from '../screens/InstagramScreen'
import { ErrorScreen } from '../screens/ErrorScreen'

export interface ScreenRoutesProps {
  quizVersion?: QuizVersion
  screensGenerator: (
    quiz: Quizes,
    onFormSubmit: OnFormSubmitCallback,
    onSaveAndCalculateSubmit: OnFormSubmitCallback,
    onConsentSubmit: OnFormSubmitCallback,
    onAdviceSubmit: OnFormSubmitCallback,
    onEmailSubmit: OnFormSubmitCallback,
    onNextClicked: OnNextClickedCallback,
    onBackClicked: OnBackClickedCallback,
    intl: IntlShape,
  ) => Screen[]
}

export const ScreenRoutes: React.FC<ScreenRoutesProps> = ({ quizVersion = 'vBluePoo', screensGenerator }) => {
  // const PATH_PREFIX = `/${quizVersion}/`
  const PATH_PREFIX = '/'

  const intl = useIntl()
  const dispatch = useDispatch<AppDispatch>()

  const quiz = useSelector(selectQuiz)

  const versionedPath = useCallback((path: string) => `${PATH_PREFIX}${path}`, [PATH_PREFIX])

  // update state screen by screen
  const onFormSubmit = async (values?: FormValues) => {
    if (values) {
      const answers = formValuesToAnswers(values)
      const { payload: newerQuiz } = await dispatch(updateAnswersInState({ quiz_version: quizVersion, answers }))

      const gtmAnswers = answers.filter((a) => GTM_QUESTION_KEY_ALLOW_LIST.includes(a.question_key))
      gtmAnswers.forEach((answer) => {
        const sanitisedAnswer = Array.isArray(answer.answer) ? answer.answer.toString() : answer.answer
        TagManager.dataLayer({
          dataLayer: quizDataLayer(answer.question_key, sanitisedAnswer, quizVersion),
        })
      })
      next(newerQuiz[quizVersion])
    } else {
      next()
    }
  }

  // only called on 1st screen after transit time input. Gives time for state to update if date-time used.
  const onAdviceSubmit = (transitTime?: Answer[]): void => {
    if (transitTime) {
      dispatch(updateAnswersInState({ quiz_version: quizVersion, answers: [...transitTime] }))
    }

    next()
  }

  // only on FFQ submit - only one which bulk sends ENTIRE state object
  const onSaveAndCalculateSubmit = async (values?: FormValues) => {
    if (values) {
      const answers = formValuesToAnswers(values)

      const { payload: newerQuiz } = await dispatch(saveAndCalculateResults({ quiz_version: quizVersion, answers }))

      const gtmAnswers = answers.filter((a) => GTM_QUESTION_KEY_ALLOW_LIST.includes(a.question_key))
      gtmAnswers.forEach((answer) => {
        const sanitisedAnswer = Array.isArray(answer.answer) ? answer.answer.toString() : answer.answer
        TagManager.dataLayer({
          dataLayer: quizDataLayer(answer.question_key, sanitisedAnswer, quizVersion),
        })
      })
      next(newerQuiz[quizVersion])
    } else {
      next()
    }
  }

  // only for screens after email submission i.e. both consent screens
  const onConsentSubmit = async (values?: FormValues) => {
    if (values) {
      const answers = formValuesToAnswers(values)
      const { payload: newerQuiz } = await dispatch(updateAndSaveAnswers({ quiz_version: quizVersion, answers }))
      const gtmAnswers = answers.filter((a) => GTM_QUESTION_KEY_ALLOW_LIST.includes(a.question_key))
      gtmAnswers.forEach((answer) => {
        const sanitisedAnswer = Array.isArray(answer.answer) ? answer.answer.toString() : answer.answer
        TagManager.dataLayer({
          dataLayer: quizDataLayer(answer.question_key, sanitisedAnswer, quizVersion),
        })
      })
      next(newerQuiz[quizVersion])
    } else {
      next()
    }
  }

  const { userApi } = useQuizService()
  const onEmailSubmit = async (values?: FormValues) => {
    if (values) {
      const email = values.email
      const answers = formValuesToAnswers(values)
      await dispatch(updateAnswersInState({ quiz_version: quizVersion, answers }))

      try {
        await userApi.registerBluePoop({ id: quiz.id, email, quiz_version: quizVersion })
      } catch (error) {
        console.error('Could not register Blue Poop subscriber: ', error)
      }
    }

    next()
  }

  const onNextClicked = () => next()
  const onBackClicked = () => previous()

  const _screens: Screen[] = screensGenerator(
    quiz[quizVersion],
    onFormSubmit,
    onSaveAndCalculateSubmit,
    onConsentSubmit,
    onAdviceSubmit,
    onEmailSubmit,
    onNextClicked,
    onBackClicked,
    intl,
  )

  const paths: string[] = useMemo(() => _screens.map((v) => versionedPath(v.path)), [_screens])

  const { previous, next } = useNavigator(paths, PATH_PREFIX, quiz[quizVersion])

  const renderScreens = (): JSX.Element[] => {
    return _screens.map((screen) => {
      switch (screen.screenType) {
        case 'form':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <FormScreen {...screen.screenProps} />}
            />
          )
        case 'toggleInputsForm':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <ToggleInputsFormScreen {...screen.screenProps} />}
            />
          )
        case 'infoOnly':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <InfoOnlyScreen {...screen.screenProps} />}
            />
          )
        case 'formMultiSubmit':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <FormMultiSubmitButtonsScreen {...screen.screenProps} />}
            />
          )
        case 'sectionEnd':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <SectionEndScreen {...screen.screenProps} />}
            />
          )
        case 'results':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={(props) => <ResultsScreen {...screen.screenProps} {...props} />}
            />
          )
        case 'error':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <ErrorScreen {...screen.screenProps} />}
            />
          )
        case 'instagram':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={(props) => <InstagramScreen {...screen.screenProps} {...props} />}
            />
          )
        case 'external':
          return (
            <Route
              key={screen.path}
              path={PATH_PREFIX + screen.path}
              render={() => <ExitScreen {...screen.screenProps} />}
            />
          )
        default:
          return <div>Loading request page</div>
      }
    })
  }

  return (
    <Switch>
      {renderScreens()}
      <Route
        key="fallback"
        // path={PATH_PREFIX} NOTE:revert when we have more that one quiz version
        path="/"
        render={(props) => <EntryScreen to={paths[0]} quizVersion={quizVersion} {...props} />}
      />
    </Switch>
  )
}
