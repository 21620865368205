import React from 'react'

export interface FieldErrorProps {
  children: React.ReactNode
  className?: string
}

export const FieldError: React.FC<FieldErrorProps> = React.memo(({ children, className }) => (
  <div className="flex flex-row items-baseline">
    <div className={`h-2 w-2 mr-2 pr-2 rounded-full bg-zoe-active block ${className}`}></div>
    <div className={`text-base-primary light ${className}`}>{children}</div>
  </div>
))
