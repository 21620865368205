import React from 'react'

import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { ScreenProps } from '../types'
import { Button } from 'components/core/buttons/Button'
import { LogoHeader } from '../../core/header/LogoHeader'
import { BackIconButton } from '../../core/buttons/BackIconButton'
import { useHistory } from 'react-router-dom'
import { TextLinkButton } from '../../core/buttons/TextLinkButton'

export type FormValues = { [key: string]: string }
export type FormValidationSchema = any | (() => any)

export interface FormProps {
  body: React.ReactNode
  initialValues?: FormValues
  validationSchema?: FormValidationSchema
}

export interface FormScreenProps extends ScreenProps {
  headerBar: React.ReactNode
  sectionTitle?: React.ReactNode
  screenTitle: React.ReactNode
  description?: React.ReactNode
  descriptionBelowForm?: React.ReactNode // only for email screen
  textAboveButton?: React.ReactNode // only for email screen
  internalLinkAboveButton?: { text: React.ReactNode; route: string }
  buttonText?: React.ReactNode
  internalLink?: { text: React.ReactNode; route: string }
  forceEnableButton?: boolean // only for cant remember screen
  form: FormProps
}

export const FormScreen: React.FC<FormScreenProps> = ({
  headerBar,
  sectionTitle,
  screenTitle,
  description,
  descriptionBelowForm,
  textAboveButton,
  internalLinkAboveButton,
  buttonText,
  internalLink,
  forceEnableButton,
  form,

  onNextClicked,
  onBackClicked,
}) => {
  const { body, initialValues = {}, validationSchema } = form
  const history = useHistory()

  return (
    <div className="h-full bg-background">
      <LogoHeader />
      {headerBar}
      <main className="flex flex-row flex-1 w-full justify-center bg-background">
        <div
          className="flex flex-col max-w-info-width-mobile sm:max-w-info-width-desktop justify-between mt-10 sm:mt-20 sm:justify-start"
          style={{ boxSizing: 'initial' }}
        >
          <div className="flex mb-4 sm:mb-6 items-center justify-between">
            {onBackClicked ? (
              <div className="mr-2 pt-1">
                <BackIconButton onClick={onBackClicked} />
              </div>
            ) : (
              <div style={{ width: '20px' }}></div>
            )}
            <p className="text-base-primary light">{sectionTitle}</p>
            <div style={{ width: '20px' }}></div>
          </div>
          <h3 className="text-center mt-4">{screenTitle}</h3>
          {description && <p className="light text-center mt-6">{description}</p>}

          {form && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                onNextClicked(values)
                setSubmitting(false)
              }}
            >
              {({ isSubmitting, isValid, dirty }) => (
                <Form className="mt-8 w-form-width-mobile sm:w-form-width-desktop mx-auto">
                  {body}
                  {descriptionBelowForm && (
                    <p className="text-base-primary mt-6 sm:mt-8 text-footnote light">{descriptionBelowForm}</p>
                  )}
                  {internalLink && (
                    <TextLinkButton onClick={() => history.push(internalLink.route)} className="mt-10 mx-auto block">
                      {internalLink.text}
                    </TextLinkButton>
                  )}
                  <div className="mt-20 mb-12 text-center">
                    {textAboveButton && <p className="text-base-primary mb-8 text-footnote light">{textAboveButton}</p>}
                    {internalLinkAboveButton && (
                      <TextLinkButton
                        onClick={() => history.push(internalLinkAboveButton.route)}
                        className="mb-8 mx-auto block"
                      >
                        {internalLinkAboveButton.text}
                      </TextLinkButton>
                    )}
                    <Button type="submit" disabled={!(forceEnableButton || (isValid && dirty)) || isSubmitting}>
                      {buttonText || <FormattedMessage id="next" />}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </main>
    </div>
  )
}
