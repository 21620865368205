import React, { useEffect } from 'react'
import { PoopPersonalityName } from '../../../../redux/quiz/types'
import { POOP_PERSONALITIES } from '../constants'
import { appConfig } from '../../../../appConfig'
import { useHistory } from 'react-router-dom'

export interface PoopPersonalityResultCardProps {
  poopPersonalityName: PoopPersonalityName
}

export const PoopPersonalityResultCard: React.FC<PoopPersonalityResultCardProps> = ({ poopPersonalityName }) => {
  const history = useHistory()
  useEffect(() => {
    if (POOP_PERSONALITIES[poopPersonalityName] === undefined) {
      history.push('results-error')
    }
  }, [])
  const poopPersonalityType = POOP_PERSONALITIES[poopPersonalityName]?.poopPersonalityType

  return (
    <div className={`w-full h-full bg-${poopPersonalityType}-background flex justify-center items-center`}>
      <div>
        <img
          src={`${appConfig.publicUrl}/img/poop/${poopPersonalityType}.png`}
          alt={poopPersonalityName}
          className="mx-auto mb-4 sm:mb-5 w-poop-personality-result-card-mobile sm:w-poop-personality-result-card-desktop"
        />
        <p className="uppercase text-tinyprint sm:text-fineprint tracking-widest light">Your poop</p>
        <p className="uppercase text-fineprint sm:text-footnote-bold tracking-wider mt-1 sm:mt-0">
          {poopPersonalityName}
        </p>
      </div>
    </div>
  )
}
