import React from 'react'
import { FieldLabel } from './FieldLabel'
import { useField, useFormikContext } from 'formik'
import { FieldError } from './FieldError'

export interface TimeInputProps {
  name: string // needed for useField(props)
  placeholder?: string
  label?: React.ReactNode
}

export const TimeInput: React.FC<TimeInputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const context = useFormikContext()

  const onChangeValidation = async (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e) // manually tell Formik to update the field value, then check overall Form validation
    await context.validateForm({
      ...(context.values as any),
      [props.name]: e.target.value, // ensures validation with the new value
    })
  }

  return (
    <div className="flex flex-col mt-4">
      {label && (
        <FieldLabel htmlFor={props.name} className="light mb-2">
          {label}
        </FieldLabel>
      )}
      <input
        className="w-full appearance-none bg-background-secondary h-input-field px-6 py-5 rounded-none border border-transparent focus:outline-none focus:border-divider-secondary placeholder-white hover:cursor-text"
        {...field} // ensure onChange comes after ...field as it overwrites default properties
        {...props}
        type="time" // degrades to text input for Safari & IE -> need placeholder to convey required format to user
        pattern="[0-9]{2}:[0-9]{2}"
        minLength={5}
        maxLength={5}
        onChange={onChangeValidation}
      />
      {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
