import { appConfig } from '../../../../appConfig'
import React from 'react'

interface PolaroidProps {
  twinGender: string
  twinName: string
  twinAge: number
  rotation: string
  size?: string
  image?: string
}

export const Polaroid: React.FC<PolaroidProps> = ({ twinGender, twinName, twinAge, rotation, size, image }) => {
  const small = size === 'small'
  const assignImg = (): string => {
    if (image) {
      return image
    }
    if (twinAge < 20) {
      return `${twinGender}-20-1.png`
    }
    if (twinAge > 69) {
      return `${twinGender}-60-3.png`
    }
    const groups = 3
    const secondDigit = parseInt(twinAge.toString()[1])
    let ageGroup
    if (secondDigit === 0) {
      ageGroup = 1
    } else {
      ageGroup = Math.ceil(secondDigit / groups)
    }
    return `${twinGender}-${Math.floor(twinAge / 10)}0-${ageGroup}.png`
  }
  return (
    <div
      className={`m-2 text-left bg-results-background-deeper-blue rounded-lg relative transform w-poop-personality-character-side transform ${rotation}`}
      style={{
        margin: 'auto',
        color: '#000',
        background: '#fff',
        width: small ? '80px' : '150px',
        height: small ? '90px' : '180px',
        boxShadow: '1px 1px 4px rgba(0,0,0,.15)',
        borderRadius: small ? '12px' : '20px',
        padding: small ? '2px' : '4px',
      }}
    >
      <img
        src={`${appConfig.publicUrl}/img/profiles/${assignImg()}`}
        alt="Divider icon"
        className="inline"
        style={{ borderRadius: small ? '12px 12px 0 0' : '20px 20px 0 0' }}
        height={small ? '116px' : '116px'}
        width={'100%'}
      />
      <div style={{ paddingLeft: '8px', paddingTop: '2px' }}>
        <h3
          style={{
            color: '#24262B',
            fontSize: small ? '13px' : '18px',
            lineHeight: small ? '0.9' : '1.2',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {twinName}
          <br />
          <span style={{ color: '#24262B', fontSize: small ? '11px' : '' }}>{twinAge}</span>
        </h3>
      </div>
    </div>
  )
}
