import React from 'react'
import { appConfig } from '../../../appConfig'

export const LogoHeader: React.FC = () => {
  return (
    <>
      <header className="pt-8 sm:pt-16">
        <img src={`${appConfig.publicUrl}/img/logos/zoe-logo.svg`} alt="ZOE logo" className="mx-auto" />
      </header>
    </>
  )
}
