import React from 'react'
import { FormattedMessage } from 'react-intl'
import { GoodMicrobesScientificName } from '../../../../redux/quiz/types'
import { GOOD_MICROBES } from '../constants'
import { GutMicrobeIcon } from './GutMicrobeIcon'

const TickSVG: React.FC = () => (
  <svg width={12} height={12} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.348 3.474l1.619 1.619 3.547-3.924" stroke="#2C8FED" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

interface GutMicrobesGridProps {
  twinName: string
  twinGoodBugsNumber: number
  twinGoodBugsNames: GoodMicrobesScientificName[]
}

export const GutMicrobesGrid: React.FC<GutMicrobesGridProps> = ({
  twinName,
  twinGoodBugsNumber,
  twinGoodBugsNames,
}) => {
  return (
    <div className="relative">
      <h3 className="light pb-4">
        <FormattedMessage id="quiz.results.gutmicrobes.grid.header" values={{ twinName: twinName }} />
      </h3>
      <p className="uppercase tracking-widest">
        <FormattedMessage
          id="quiz.results.gutmicrobes.grid.header_2"
          values={{ twinGoodBugsNumber: twinGoodBugsNumber, totalGoodBugs: GOOD_MICROBES.length }}
        />
      </p>
      <div className="mt-8 mx-auto grid md:grid-cols-5 md:grid-rows-3 grid-cols-3 grid-rows-5 sm:flex-wrap sm:justify-center">
        {GOOD_MICROBES.map(({ number, nickname, scientificName }) => {
          return (
            <div
              key={number}
              className="p-2 m-2 bg-results-background-deeper-blue rounded-lg relative"
              style={{ minWidth: '30%' }}
            >
              <span
                className="bg-results-background-deepest-blue text-center"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  borderRadius: '20px',
                  height: '20px',
                  width: '20px',
                  padding: '4px',
                }}
              >
                {twinGoodBugsNames.includes(scientificName) && <TickSVG />}
              </span>
              <GutMicrobeIcon
                number={number}
                nickname={nickname}
                present={twinGoodBugsNames.includes(scientificName)}
                eyes={true}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
