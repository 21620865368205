import React from 'react'
import { appConfig } from '../../../appConfig'

export type BackIconButtonProps = React.ComponentProps<'button'>

export const BackIconButton: React.FC<BackIconButtonProps> = React.memo((props) => (
  <button
    className="appearance-none flex items-center w-5 h-full border-0 bg-transparent no-underline cursor-pointer focus:outline-none"
    {...props}
  >
    <img src={`${appConfig.publicUrl}/img/icons/back.svg`} alt="Back" />
  </button>
))
