import React from 'react'
import { PoopPersonalityResultCard, PoopPersonalityResultCardProps } from './PoopPersonalityResultCard'
import { TransitTimeResultCard, TransitTimeResultCardProps } from './TransitTimeResultCard'
import { GutTwinResultCard, GutTwinResultCardProps } from './GutTwinResultCard'
import { GutMicrobesResultCard, GutMicrobesResultCardProps } from './GutMicrobesResultCard'

interface PoopPersonalityCardProps extends PoopPersonalityResultCardProps {
  metric: 'poopPersonality'
  className?: string
}

interface TransitTimeCardProps extends TransitTimeResultCardProps {
  metric: 'transitTime'
  className?: string
}

interface GutTwinCardProps extends GutTwinResultCardProps {
  metric: 'gutTwin'
  className?: string
}

interface GutMicrobesCardProps extends GutMicrobesResultCardProps {
  metric: 'gutMicrobes'
  className?: string
}

type ResultCardProps = PoopPersonalityCardProps | TransitTimeCardProps | GutTwinCardProps | GutMicrobesCardProps

export const ResultCard: React.FC<ResultCardProps> = (props) => {
  const renderCard = (resultCard: ResultCardProps) => {
    switch (resultCard.metric) {
      case 'poopPersonality':
        return <PoopPersonalityResultCard poopPersonalityName={resultCard.poopPersonalityName} />
      case 'transitTime':
        return <TransitTimeResultCard transitTimeHrs={resultCard.transitTimeHrs} />
      case 'gutTwin':
        return (
          <GutTwinResultCard
            name={resultCard.name}
            age={resultCard.age}
            twinName={resultCard.twinName}
            twinGender={resultCard.twinGender}
            twinAge={resultCard.twinAge}
          />
        )
      case 'gutMicrobes':
        return <GutMicrobesResultCard twinGoodBugsNumber={resultCard.twinGoodBugsNumber} />
    }
  }
  return (
    <div
      className={`w-result-card-mobile h-result-card-mobile sm:w-result-card-desktop sm:h-result-card-desktop ${props.className}`}
    >
      {renderCard(props)}
    </div>
  )
}
