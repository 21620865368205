import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FieldLabel } from './FieldLabel'
import { useField, useFormikContext } from 'formik'

export interface DateInputProps {
  name: string // needed for useField(props)
  placeholder?: string
  label?: React.ReactNode
  minDate?: Date
  maxDate?: Date
}

export const DateInput: React.FC<DateInputProps> = ({ label, ...props }) => {
  const [field] = useField(props)
  const context = useFormikContext()
  const dateInputRef = useRef<HTMLDivElement>(null)

  const onChangeValidation = async (value: Date) => {
    context.setFieldValue(field.name, value)
    await context.validateForm({
      ...(context.values as any),
      [props.name]: value,
    })
  }

  const goToDateField = () => {
    dateInputRef.current && dateInputRef.current.scrollIntoView(true)
  }

  const hideMobileKeyboard = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.readOnly = true
    e.target.blur()
  }

  return (
    <div className="flex flex-col" ref={dateInputRef} onClick={goToDateField}>
      {label && (
        <FieldLabel htmlFor={props.name} className="light mb-2">
          {label}
        </FieldLabel>
      )}

      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onChange={(value: Date) => onChangeValidation(value)}
        placeholderText={props.placeholder}
        dateFormat="MMM dd, yyyy"
        // dateFormat="MMM dd, yyyy, h:mm aa"
        // showTimeInput
        // timeInputLabel="Time:"
        popperPlacement="bottom"
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
        onFocus={(e) => hideMobileKeyboard(e)}
      />
    </div>
  )
}
