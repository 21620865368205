import React from 'react'
import Lottie from 'react-lottie'

interface LottieAnimationProps {
  source: React.ReactNode
  width: number
  height: number
}

export const LottieAnimation: React.FC<LottieAnimationProps> = ({ source, width, height }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: source,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <>
      <Lottie options={defaultOptions} height={height} width={width} />
    </>
  )
}
