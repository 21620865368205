import React, { useEffect } from 'react'
import { appConfig } from '../../../../appConfig'
import { PoopPersonalityName } from '../../../../redux/quiz/types'
import { FormattedMessage } from 'react-intl'
import { POOP_PERSONALITIES } from '../constants'
import { BluePoopChallengeHashtag } from './BluePoopChallengeHashtag'
import { LottieAnimation } from '../../../core/lottie/LottieAnimation'
import { useHistory } from 'react-router-dom'

export interface PoopPersonalityResultProps {
  poopPersonalityName: PoopPersonalityName
}

export const PoopPersonalityResult: React.FC<PoopPersonalityResultProps> = ({ poopPersonalityName }) => {
  const history = useHistory()
  useEffect(() => {
    if (POOP_PERSONALITIES[poopPersonalityName] === undefined) {
      history.push('results-error')
    }
  }, [])

  const poopPersonalityType = POOP_PERSONALITIES[poopPersonalityName]?.poopPersonalityType
  const PoopAnimation = POOP_PERSONALITIES[poopPersonalityName]?.animatedFilename

  return (
    <div className={`w-full bg-${poopPersonalityType}-background`}>
      <div className="mx-auto pt-4 pb-12 sm:pb-20 max-w-info-width-mobile sm:max-w-results-width-desktop text-center">
        <div className="mx-auto mb-4">
          <LottieAnimation source={PoopAnimation} width={256} height={256} />
        </div>
        <p className="light mb-2">
          <FormattedMessage id="quiz.poop_personality.screen_title" />
        </p>
        <h3 className="text-center uppercase leading-10 tracking-widest">{poopPersonalityName}</h3>
        <p className="light mt-4 mb-10">
          <FormattedMessage id={`quiz.poop_personality.description.${poopPersonalityType}`} />
        </p>
        <BluePoopChallengeHashtag />
      </div>

      <div className={`w-full pt-1 bg-${poopPersonalityType}-background-secondary text-center relative`}>
        <div
          className={`w-0 h-0 border-b-14 border-${poopPersonalityType}-background-secondary right-section-arrow absolute`}
          style={{
            borderLeft: '14px solid transparent',
            borderRight: '14px solid transparent',
            top: -14,
          }}
        ></div>
        <div className="mx-auto pt-24 pb-16 sm:pb-20 max-w-info-width-mobile sm:max-w-results-width-desktop">
          <div
            className={`bg-${poopPersonalityType}-background rounded-2xl pt-10 pb-12 px-4 sm:px-16 sm:py-12 relative`}
          >
            <div
              className={`w-0 h-0 border-b-14 border-${poopPersonalityType}-background right-large-speech-arrow absolute`}
              style={{
                borderLeft: '14px solid transparent',
                top: -14,
              }}
            ></div>
            <img
              src={`${appConfig.publicUrl}/img/poop/${poopPersonalityType}.png`}
              alt={poopPersonalityName}
              className="w-poop-personality-character-front absolute"
              style={{
                top: -90,
                right: 45,
              }}
            />
            <h2 className="uppercase tracking-wider mb-3">
              <FormattedMessage id="quiz.poop_personality.going_well.title" />
            </h2>
            <p className="light">
              <FormattedMessage id={`quiz.poop_personality.going_well.${poopPersonalityType}`} />
            </p>
            <hr className="my-8 opacity-20" />
            <h2 className="uppercase tracking-wider mb-3">
              <FormattedMessage id="quiz.poop_personality.work_on.title" />
            </h2>
            <p className="light">
              {/*<FormattedMessage id={`quiz.poop_personality.work_on.${poopPersonalityType}`} />*/}
              <FormattedMessage id="quiz.poop_personality.work_on.generic" />
            </p>
          </div>
          <p className="text-fineprint light mt-6 opacity-60 sm:text-center sm:mt-10">
            <FormattedMessage id="quiz.transit_time_results.disclaimer" />
          </p>

          <div className="mt-12 mb-6 relative">
            <div
              className={`bg-${poopPersonalityType}-background rounded-2xl pt-3 pb-4 px-8 w-small-speech-bubble relative mx-auto`}
            >
              <FormattedMessage id="quiz.poop_personality.smelling_good" />
              <div
                className={`w-0 h-0 border-t-14 border-${poopPersonalityType}-background left-speech-arrow-left absolute`}
                style={{
                  borderRight: '14px solid transparent',
                  bottom: -14,
                }}
              ></div>
              <img
                src={`${appConfig.publicUrl}/img/poop/${poopPersonalityType}.png`}
                alt={poopPersonalityName}
                className="w-poop-personality-character-side transform rotate-12 -ml-12 absolute"
                style={{ top: 10, left: -60 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
