import React from 'react'
import moment from 'moment'

import { FormattedMessage, IntlShape } from 'react-intl'

import { FormScreenProps } from '../screens/FormScreen'
import { InfoOnlyScreenProps } from '../screens/InfoOnlyScreen'
import { ExitScreenProps } from '../screens/ExitScreen'
import { HeightWeightForm, validationSchema as heightWeightValidationSchema } from './forms/HeightWeightForm'
import { Quizes } from 'redux/quiz/types'
import { OnBackClickedCallback, OnFormSubmitCallback, OnNextClickedCallback } from '../types'
import { InputField } from 'components/core/fields/InputField'
import { POOP_PERSONALITIES, STAGE_INFO, TOTAL_STAGES } from './constants'
import { RadioButtonRound } from '../../core/fields/RadioButtonRound'
import { StageProgressBar } from '../../core/bars/StageProgressBar'
import * as Yup from 'yup'
import { PrivacyPolicy } from './components/PrivacyPolicy'
import { RadioButtonGroup } from '../../core/fields/RadioButtonGroup'
import { FormMultiSubmitButtonsScreenProps } from '../screens/FormMultiSubmitButtonsScreen'
import { ToggleInputsFormScreenProps } from '../screens/ToggleInputsFormScreen'
import { FFQForm, validationSchema as ffqValidationSchema } from './forms/FFQForm'
import { DateInput } from '../../core/fields/DateInput'
import { transitTime } from '../../../data/transitTime'
import { TimeInput } from '../../core/fields/TimeInput'
import { ResultsScreenProps } from '../screens/ResultsScreen'
import { LocationForm, validationSchema as locationValidationSchema } from './forms/LocationForm'
import { SectionEndScreenProps } from '../screens/SectionEndScreen'
import { ResultsFooter } from './components/ResultsFooter'
import { InstagramScreenProps } from '../screens/InstagramScreen'
import { ErrorScreenProps } from '../screens/ErrorScreen'
import { ResultsError } from './textComponents/ResultsError'
import { PrivacyPolicyScience } from './components/PrivacyPolicyScience'
import { ReturnToHomepageWhyScreen } from './textComponents/ReturnToHomepageWhyScreen'
import { EmailDescription } from './textComponents/EmailDescription'

export type Screen = {
  path: string
} & ScreenPropTypes

export type ScreenPropTypes =
  | {
      screenType: 'form'
      screenProps: FormScreenProps
    }
  | {
      screenType: 'toggleInputsForm'
      screenProps: ToggleInputsFormScreenProps
    }
  | {
      screenType: 'infoOnly'
      screenProps: InfoOnlyScreenProps
    }
  | {
      screenType: 'formMultiSubmit'
      screenProps: FormMultiSubmitButtonsScreenProps
    }
  | {
      screenType: 'sectionEnd'
      screenProps: SectionEndScreenProps
    }
  | {
      screenType: 'results'
      screenProps: ResultsScreenProps
    }
  | {
      screenType: 'error'
      screenProps: ErrorScreenProps
    }
  | {
      screenType: 'instagram'
      screenProps: InstagramScreenProps
    }
  | {
      screenType: 'external'
      screenProps: ExitScreenProps
    }

export const screens = (
  quiz: Quizes,
  onFormSubmit: OnFormSubmitCallback,
  onSaveAndCalculateSubmit: OnFormSubmitCallback,
  onConsentSubmit: OnFormSubmitCallback,
  onAdviceSubmit: OnFormSubmitCallback,
  onEmailSubmit: OnFormSubmitCallback,
  onNextClicked: OnNextClickedCallback,
  onBackClicked: OnBackClickedCallback,
  intl: IntlShape,
): Screen[] => [
  {
    path: 'why',
    screenType: 'sectionEnd',
    screenProps: {
      headerImage: 'poop.svg',
      headline: <FormattedMessage id="quiz.part1" />,
      screenTitle: <FormattedMessage id="quiz.part1.headline" />,
      poopCharacter: 'thunderpoop',
      poopText: <FormattedMessage id="quiz.part1.poop_text" />,
      poopTextBackgroundColour: 'thunderpoop-background',
      footnote: <ReturnToHomepageWhyScreen />,
      onNextClicked,
    },
  },
  {
    path: 'start-date',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={1}
          stepsInStage={STAGE_INFO.transitTime.stepsInStage}
          currentStage={STAGE_INFO.transitTime.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.transit_time" />,
      screenTitle: <FormattedMessage id="quiz.start_date.title" />,
      internalLink: { text: <FormattedMessage id="quiz.date.input_transit_time" />, route: 'transit-time' },
      internalLinkAboveButton: { text: <FormattedMessage id="quiz.date.cant_remember" />, route: 'cant-remember' },
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <>
            <DateInput
              label={intl.formatMessage({ id: 'quiz.start_date.label' })}
              name="start_date"
              placeholder={intl.formatMessage({ id: 'quiz.start_date.placeholder' })}
              maxDate={new Date()}
            />
            <TimeInput
              label={intl.formatMessage({ id: 'quiz.start_time.label' })}
              name="start_time"
              placeholder={intl.formatMessage({ id: 'quiz.start_time.placeholder' })}
            />
          </>
        ),
        validationSchema: Yup.object({
          start_date: Yup.date()
            .max(new Date(), intl.formatMessage({ id: 'quiz.start_date.error.max' }))
            .required(),
          start_time: Yup.string().required(intl.formatMessage({ id: 'quiz.start_time.error.required' })),
        }),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { ['start_date']: '', ['start_time']: '' },
      },
      onNextClicked: onFormSubmit,
    },
  },
  {
    path: 'end-date',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={2}
          stepsInStage={STAGE_INFO.transitTime.stepsInStage}
          currentStage={STAGE_INFO.transitTime.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.transit_time" />,
      screenTitle: <FormattedMessage id="quiz.end_date.title" />,
      internalLink: { text: <FormattedMessage id="quiz.date.input_transit_time" />, route: 'transit-time' },
      internalLinkAboveButton: { text: <FormattedMessage id="quiz.date.cant_remember" />, route: 'cant-remember' },
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <>
            <DateInput
              label={intl.formatMessage({ id: 'quiz.end_date.label' })}
              name="end_date"
              placeholder={intl.formatMessage({ id: 'quiz.end_date.placeholder' })}
              minDate={new Date(quiz.start_date)}
              maxDate={new Date()}
            />
            <TimeInput
              label={intl.formatMessage({ id: 'quiz.end_time.label' })}
              name="end_time"
              placeholder={intl.formatMessage({ id: 'quiz.end_time.placeholder' })}
            />
          </>
        ),
        validationSchema: Yup.object({
          end_date: Yup.date()
            .max(new Date(), intl.formatMessage({ id: 'quiz.end_date.error.max' }))
            .required(),
          end_time: Yup.string()
            .required(intl.formatMessage({ id: 'quiz.end_time.error.required' }))
            .test('min_end_time', intl.formatMessage({ id: 'quiz.end_time.error.min_time' }), function (value) {
              const { end_date } = this.parent
              if (quiz.start_date === moment(end_date).format('ddd MMM DD YYYY')) {
                return moment(value, 'HH:mm').isSameOrAfter(moment(quiz.start_time, 'HH:mm').add(1, 'hours'))
              } else {
                return true
              }
            }),
        }),
        // needed to prevent React "uncontrolled" error showing
        initialValues: {
          ['end_date']: '',
          ['end_time']: '',
          ['transit_time_days_input']: '',
          ['transit_time_hrs_input']: '',
        },
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'cant-remember',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={2}
          stepsInStage={STAGE_INFO.transitTime.stepsInStage}
          currentStage={STAGE_INFO.transitTime.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.transit_time" />,
      screenTitle: <FormattedMessage id="quiz.cant_remember.title" />,
      description: <FormattedMessage id="quiz.cant_remember.description" values={{ br: <br /> }} />,
      form: {
        body: <></>,
        // Average GTT of 28.7 hours if can't remember
        initialValues: {
          ['transit_time_days_input']: '1',
          ['transit_time_hrs_input']: '4.7',
          ['start_date']: '',
          ['start_time']: '',
          ['end_date']: '',
          ['end_time']: '',
        },
      },
      forceEnableButton: true,
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'transit-time',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={2}
          stepsInStage={STAGE_INFO.transitTime.stepsInStage}
          currentStage={STAGE_INFO.transitTime.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.transit_time" />,
      screenTitle: <FormattedMessage id="quiz.transit_time.title" />,
      internalLink: { text: <FormattedMessage id="quiz.transit_time.input_date" />, route: 'start-date' },
      internalLinkAboveButton: { text: <FormattedMessage id="quiz.date.cant_remember" />, route: 'cant-remember' },
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <>
            <InputField
              label={intl.formatMessage({ id: 'quiz.transit_time.days.label' })}
              name="transit_time_days_input"
              type="number"
            />
            <div className="mt-4">
              <InputField
                label={intl.formatMessage({ id: 'quiz.transit_time.hours.label' })}
                name="transit_time_hrs_input"
                type="number"
              />
            </div>
          </>
        ),
        validationSchema: Yup.object({
          transit_time_days_input: Yup.number()
            .min(0, intl.formatMessage({ id: 'quiz.transit_time.days.error' }))
            .max(30, intl.formatMessage({ id: 'quiz.transit_time.days.error.max' }))
            .required(intl.formatMessage({ id: 'quiz.transit_time.days.error' })),
          transit_time_hrs_input: Yup.number()
            .min(0, intl.formatMessage({ id: 'quiz.transit_time.hours.error' }))
            .max(24, intl.formatMessage({ id: 'quiz.transit_time.hours.error.max' }))
            .when('transit_time_days_input', {
              is: 0,
              then: Yup.number().min(1, intl.formatMessage({ id: 'quiz.transit_time.hours.error.min' })),
            })
            .required(intl.formatMessage({ id: 'quiz.transit_time.hours.error' })),
        }),
        // needed to prevent React "uncontrolled" error showing
        initialValues: {
          ['transit_time_days_input']: '',
          ['transit_time_hrs_input']: '',
          ['start_date']: '',
          ['start_time']: '',
          ['end_date']: '',
          ['end_time']: '',
        },
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'study-1',
    screenType: 'infoOnly',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={3}
          stepsInStage={STAGE_INFO.transitTime.stepsInStage}
          currentStage={STAGE_INFO.transitTime.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      screenTitle: <FormattedMessage id="quiz.study_1.title" values={{ br: <br /> }} />,
      description: <FormattedMessage id="quiz.study_1.description" values={{ br: <br /> }} />,
      buttonText: <FormattedMessage id="next" />,
      transitTimeToBeSubmitted: transitTime(
        quiz.start_date,
        quiz.start_time,
        quiz.end_date,
        quiz.end_time,
        quiz.transit_time_days_input,
        quiz.transit_time_hrs_input,
      ),
      onNextClicked: onAdviceSubmit,
    },
  },
  {
    path: 'study-2',
    screenType: 'infoOnly',
    screenProps: {
      backgroundColour: 'background-tertiary',
      headerBar: (
        <StageProgressBar
          currentStep={4}
          stepsInStage={STAGE_INFO.transitTime.stepsInStage}
          currentStage={STAGE_INFO.transitTime.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      screenTitle: <FormattedMessage id="quiz.study_2.title" />,
      description: <FormattedMessage id="quiz.study_2.description" values={{ br: <br /> }} />,
      buttonText: <FormattedMessage id="next" />,
      onNextClicked,
    },
  },
  {
    path: 'body-intro',
    screenType: 'sectionEnd',
    screenProps: {
      headerImage: 'poop.svg',
      screenTitle: <FormattedMessage id="quiz.part2.title" values={{ br: <br /> }} />,
      poopCharacter: 'pooptastrophe',
      poopText: <FormattedMessage id="quiz.part2.poop_text" />,
      poopTextBackgroundColour: 'pooptastrophe-background',
      onNextClicked,
    },
  },
  {
    path: 'age',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={2}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.body" />,
      screenTitle: <FormattedMessage id="quiz.age.title" />,
      description: <FormattedMessage id="quiz.age.explanation" />,
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <>
            <InputField
              label={intl.formatMessage({ id: 'quiz.age.label' })}
              name="age"
              type="number"
              placeholder={intl.formatMessage({ id: 'quiz.age.placeholder' })}
            />
          </>
        ),
        validationSchema: Yup.object({
          age: Yup.number()
            .min(18, intl.formatMessage({ id: 'quiz.age.field.error.min' }))
            .max(110, intl.formatMessage({ id: 'quiz.age.field.error.max' }))
            .integer(intl.formatMessage({ id: 'quiz.age.field.error.integer' }))
            .required(intl.formatMessage({ id: 'quiz.age.field.error.required' })),
        }),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { ['age']: '' },
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'height-weight',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={3}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.body" />,
      screenTitle: <FormattedMessage id="quiz.height_weight.title" />,
      description: <FormattedMessage id="quiz.height_weight.explanation" />,
      descriptionBelowForm: <FormattedMessage id="quiz.height_weight.conversion" />,
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: <HeightWeightForm />,
        validationSchema: heightWeightValidationSchema(intl),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { ['height_ft']: '', ['height_in']: '', ['weight']: '' },
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'sex',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={4}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.body" />,
      screenTitle: <FormattedMessage id="quiz.sex.title" />,
      description: <FormattedMessage id="quiz.sex.explanation" />,
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <RadioButtonGroup name="sex">
            <RadioButtonRound name="sex" id="male" value="male" label={intl.formatMessage({ id: 'quiz.sex.male' })} />
            <RadioButtonRound
              name="sex"
              id="female"
              value="female"
              label={intl.formatMessage({ id: 'quiz.sex.female' })}
            />
            <RadioButtonRound
              name="sex"
              id="intersex"
              value="intersex"
              label={intl.formatMessage({ id: 'quiz.sex.intersex' })}
            />
          </RadioButtonGroup>
        ),
        validationSchema: Yup.object({
          sex: Yup.string().required(),
        }),
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'gender',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={5}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.body" />,
      screenTitle: <FormattedMessage id="quiz.gender.title" />,
      description: <FormattedMessage id="quiz.gender.explanation" />,
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <RadioButtonGroup name="gender">
            <RadioButtonRound
              name="gender"
              id="male"
              value="male"
              label={intl.formatMessage({ id: 'quiz.gender.male' })}
            />
            <RadioButtonRound
              name="gender"
              id="female"
              value="female"
              label={intl.formatMessage({ id: 'quiz.gender.female' })}
            />
            <RadioButtonRound
              name="gender"
              id="other"
              value="other"
              label={intl.formatMessage({ id: 'quiz.gender.other' })}
            />
            <RadioButtonRound
              name="gender"
              id="pnts"
              value="pnts"
              label={intl.formatMessage({ id: 'quiz.gender.pnts' })}
            />
          </RadioButtonGroup>
        ),
        validationSchema: Yup.object({
          gender: Yup.string().required(),
        }),
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'location',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={6}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.body" />,
      screenTitle: <FormattedMessage id="quiz.location.title" />,
      description: <FormattedMessage id="quiz.location.explanation" />,
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: <LocationForm />,
        validationSchema: locationValidationSchema(intl),
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'name',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={1}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.body" />,
      screenTitle: <FormattedMessage id="quiz.name.title" />,
      description: <FormattedMessage id="quiz.name.explanation" />,
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: (
          <>
            <InputField label={intl.formatMessage({ id: 'quiz.name.label' })} name="name" type="text" />
          </>
        ),
        validationSchema: Yup.object({
          name: Yup.string().required(intl.formatMessage({ id: 'quiz.name.field.error.required' })),
        }),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { ['name']: '' },
      },
      onNextClicked: onFormSubmit,
      onBackClicked,
    },
  },
  {
    path: 'gut-1',
    screenType: 'infoOnly',
    screenProps: {
      backgroundColour: 'background-quaternary',
      headerBar: (
        <StageProgressBar
          currentStep={7}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: 'Did you know?',
      screenTitle: <FormattedMessage id="quiz.gut_1.title" />,
      buttonText: <FormattedMessage id="next" />,
      onNextClicked,
    },
  },
  {
    path: 'gut-2',
    screenType: 'infoOnly',
    screenProps: {
      backgroundColour: 'background-quinary',
      headerBar: (
        <StageProgressBar
          currentStep={8}
          stepsInStage={STAGE_INFO.body.stepsInStage}
          currentStage={STAGE_INFO.body.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.gut_2" />,
      screenTitle: <FormattedMessage id="quiz.gut_2.title" />,
      description: <FormattedMessage id="quiz.gut_2.description" values={{ br: <br /> }} />,
      buttonText: <FormattedMessage id="next" />,
      onNextClicked,
    },
  },
  {
    path: 'diet-intro',
    screenType: 'sectionEnd',
    screenProps: {
      headerImage: 'poop.svg',
      sectionTitle: <FormattedMessage id="quiz.part3" />,
      screenTitle: <FormattedMessage id="quiz.part3.title" values={{ br: <br /> }} />,
      poopCharacter: 'scaredypoop',
      poopText: <FormattedMessage id="quiz.part3.poop_text" values={{ br: <br /> }} />,
      poopTextBackgroundColour: 'results-background-deep-blue',
      onNextClicked,
    },
  },
  {
    path: 'diet',
    screenType: 'toggleInputsForm',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={1}
          stepsInStage={STAGE_INFO.diet.stepsInStage}
          currentStage={STAGE_INFO.diet.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.diet" />,
      screenTitle: <FormattedMessage id="quiz.diet.title" />,
      description: <FormattedMessage id="quiz.diet.explanation" values={{ br: <br /> }} />,
      icon: 'down-arrow',
      buttonText: <FormattedMessage id="next" />,
      form: {
        body: <FFQForm />,
        validationSchema: ffqValidationSchema(),
      },
      onNextClicked: onSaveAndCalculateSubmit,
      onBackClicked,
    },
  },
  {
    path: 'email',
    screenType: 'form',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={2}
          stepsInStage={STAGE_INFO.diet.stepsInStage}
          currentStage={STAGE_INFO.diet.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.category.report" />,
      screenTitle: <FormattedMessage id="quiz.email.title" />,
      description: <EmailDescription />,
      descriptionBelowForm: <PrivacyPolicy />,
      buttonText: <FormattedMessage id="quiz.email.next.button" />,
      form: {
        body: (
          <InputField label={intl.formatMessage({ id: 'quiz.email.email_field.label' })} name="email" type="email" />
        ),
        // needed to prevent React "uncontrolled" error showing
        initialValues: {
          ['email']: '',
        },
        validationSchema: Yup.object({
          email: Yup.string()
            .email(intl.formatMessage({ id: 'quiz.email.email_field.error' }))
            .required(intl.formatMessage({ id: 'quiz.email.email_field.error' })),
        }),
      },
      onNextClicked: onEmailSubmit,
    },
  },
  {
    path: 'science',
    screenType: 'formMultiSubmit',
    screenProps: {
      headerBar: (
        <StageProgressBar
          currentStep={3}
          stepsInStage={STAGE_INFO.diet.stepsInStage}
          currentStage={STAGE_INFO.diet.currentStage}
          totalStages={TOTAL_STAGES}
        />
      ),
      sectionTitle: <FormattedMessage id="quiz.science.title" />,
      screenTitle: <FormattedMessage id="quiz.science.sub_title" />,
      description: <PrivacyPolicyScience />,
      primaryButtonText: <FormattedMessage id="quiz.science.yes.button" />,
      secondaryButtonText: <FormattedMessage id="quiz.science.no.button" />,
      form: {
        initialValues: {
          science: 'true',
        },
      },
      onNextClicked: onConsentSubmit,
    },
  },
  {
    path: 'poop-personality',
    screenType: 'results',
    screenProps: {
      version: 'vBluePoo',
      bodyVariation: 'poopPersonality',
      nextPageIntro: (
        <ResultsFooter
          backgroundColour={`${POOP_PERSONALITIES[quiz.poop_personality]?.poopPersonalityType}-background-secondary`}
          textAboveButton={intl.formatMessage({ id: 'quiz.results_1.footer' })}
          buttonText={intl.formatMessage({ id: 'go_to_results_2' })}
          onNextClicked={onNextClicked}
        />
      ),
      footer: (
        <ResultsFooter
          backgroundColour={`${POOP_PERSONALITIES[quiz.poop_personality]?.poopPersonalityType}-background-secondary`}
          buttonText={intl.formatMessage({ id: 'go_to_results_2' })}
          onNextClicked={onNextClicked}
        />
      ),
    },
  },
  {
    path: 'transit-time-results',
    screenType: 'results',
    screenProps: {
      version: 'vBluePoo',
      backgroundColour: 'stormpooper-background-secondary',
      secondaryBackgroundColour: 'results-background-dark-blue',
      bodyVariation: 'transitTime',
      nextPageIntro: (
        <ResultsFooter
          backgroundColour="results-background-dark-blue"
          textAboveButton={intl.formatMessage({ id: 'quiz.results_2.footer' })}
          buttonText={intl.formatMessage({ id: 'go_to_results_3' })}
          onNextClicked={onNextClicked}
        />
      ),
      footer: (
        <ResultsFooter
          backgroundColour="results-background-dark-blue"
          buttonText={intl.formatMessage({ id: 'go_to_results_3' })}
          onNextClicked={onNextClicked}
        />
      ),
    },
  },
  {
    path: 'gut-twin',
    screenType: 'results',
    screenProps: {
      version: 'vBluePoo',
      backgroundColour: 'results-background-teal',
      secondaryBackgroundColour: 'results-background-dark-green',
      bodyVariation: 'gutTwin',
      nextPageIntro: (
        <ResultsFooter
          backgroundColour="results-background-dark-green"
          textAboveButton={intl.formatMessage({ id: 'quiz.results_3.footer' })}
          buttonText={intl.formatMessage({ id: 'go_to_results_4' })}
          onNextClicked={onNextClicked}
        />
      ),
      footer: (
        <ResultsFooter
          backgroundColour="results-background-dark-green"
          buttonText={intl.formatMessage({ id: 'go_to_results_4' })}
          onNextClicked={onNextClicked}
        />
      ),
    },
  },
  {
    path: 'gut-microbes',
    screenType: 'results',
    screenProps: {
      version: 'vBluePoo',
      backgroundColour: 'results-background-deep-blue',
      secondaryBackgroundColour: 'results-background-deep-blue',
      bodyVariation: 'gutMicrobes',
      nextPageIntro: (
        <ResultsFooter
          backgroundColour="results-background-deep-blue"
          textAboveButton={intl.formatMessage({ id: 'quiz.results_4.footer' })}
          buttonText={intl.formatMessage({ id: 'go_to_results_5' })}
          onNextClicked={onNextClicked}
        />
      ),
    },
  },
  {
    path: 'summary',
    screenType: 'results',
    screenProps: {
      version: 'vBluePoo',
      backgroundColour: 'white',
      bodyVariation: 'summary',
    },
  },
  {
    path: 'done',
    screenType: 'external',
    screenProps: {
      to: 'https://joinzoe.com',
    },
  },
  {
    path: 'results-error',
    screenType: 'error',
    screenProps: {
      headline: <FormattedMessage id="quiz.results_error.headline" />,
      description: <ResultsError />,
      redirectTo: 'https://joinzoe.com/bluepoop',
    },
  },
  {
    path: 'instagram-1',
    screenType: 'instagram',
    screenProps: {
      subTitle: <FormattedMessage id="quiz.instagram_1.subtitle" />,
      animatedImage: true,
      instructions: <FormattedMessage id="quiz.instagram_1.instructions" />,
      buttonText: <FormattedMessage id="next" />,
      onNextClicked,
    },
  },
  {
    path: 'instagram-2',
    screenType: 'instagram',
    screenProps: {
      subTitle: <FormattedMessage id="quiz.instagram_2.subtitle" />,
      instructions: <FormattedMessage id="quiz.instagram_2.instructions" />,
      sharingText: true,
      buttonText: <FormattedMessage id="quiz.instagram.copy_caption" />,
      onNextClicked,
    },
  },
]
