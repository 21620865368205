import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'

import { QuizServiceProvider } from 'contexts/QuizServiceContext'
import { defaultRichTextElements } from 'i18n/utils/formatting'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from 'redux/store'
import { MessageFormatElement } from 'intl-messageformat-parser'

import TagManager from 'react-gtm-module'
import { appConfig } from 'appConfig'

import { ScreenRoutes as VBluePooScreenRoutes } from './components/pages/vBluePoo/ScreenRoutes'
import { screens as screensBluePoo } from './components/pages/vBluePoo/screens'
import { CookiesProvider } from 'react-cookie'
import ScrollToTop from './components/core/navigation/ScrollToTop'

const { persistor, store } = configureStore()

TagManager.initialize({ gtmId: appConfig.gtmId })

export interface AppProps {
  locale: string
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
}

const App: React.FC<AppProps> = (props) => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CookiesProvider>
        <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME || '/'}>
          <ScrollToTop />
          <IntlProvider
            locale={props.locale}
            messages={props.messages}
            defaultRichTextElements={defaultRichTextElements}
          >
            <QuizServiceProvider>
              <VBluePooScreenRoutes quizVersion="vBluePoo" screensGenerator={screensBluePoo} />
            </QuizServiceProvider>
          </IntlProvider>
        </BrowserRouter>
      </CookiesProvider>
    </PersistGate>
  </ReduxProvider>
)

export default App

// TODO: Find better location
export type AppDispatch = typeof store.dispatch
