import React, { useEffect, useState } from 'react'
import { appConfig } from '../../../../appConfig'
import { PoopPersonalityType } from '../../../../redux/quiz/types'

export interface CopyTextIconProps {
  poopPersonalityType: PoopPersonalityType
  mobileVersion?: boolean
}

export const CopyTextIcon: React.FC<CopyTextIconProps> = ({ poopPersonalityType, mobileVersion }) => {
  const [copySuccess, setCopySuccess] = useState('')

  function copyToClipboard() {
    const tempInput = document.createElement('input')
    tempInput.value = 'https://joinzoe.com/bluepoop/' + poopPersonalityType
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    setCopySuccess('Copied')
  }

  useEffect(() => {
    setTimeout(() => setCopySuccess(''), 2000)
  }, [copySuccess])

  return (
    <>
      {document.queryCommandSupported('copy') && (
        <div id="copy-icon" onClick={() => copyToClipboard()} className="cursor-pointer">
          {mobileVersion ? (
            <div className="mt-8 flex justify-center">
              <img src={`${appConfig.publicUrl}/img/social/copy-mobile.svg`} alt="Copy" className="" />
              <p className="ml-2 text-base-secondary text-sm light">
                {copySuccess ? <span className="blink">{copySuccess}</span> : 'Copy link'}
              </p>
            </div>
          ) : (
            <img src={`${appConfig.publicUrl}/img/social/copy.png`} alt="Copy" className="max-w-social-icon" />
          )}
        </div>
      )}
    </>
  )
}
