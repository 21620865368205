import React from 'react'
import { FormattedMessage } from 'react-intl'

export const PrivacyPolicyScience: React.FC = () => {
  return (
    <>
      <p className="light">
        <FormattedMessage id="quiz.science.description" />
      </p>
      <br />
      <br />
      <p className="text-footnote light">
        We take data privacy very seriously. You can read how your data will be treated in our{' '}
        <a href="https://joinzoe.com/bluepoop/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy.
        </a>
      </p>
    </>
  )
}
